import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//navigation
import { useNavigate } from "react-router";

// import image from assets
import Maxsus from "../../assets/MAXSUS.png";
import Magistr from "../../assets/MAGISTR.png";

// import context
import DataContext from "../../context/dataContext.jsx";

// import file
import Footer from "../../components/Footer/index.jsx";

//import css
import "./style.css";
import Header from "../../components/Header";

const Main = () => {
  let navigate = useNavigate();
  const { changeMaster, bachelor } = useContext(DataContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      <Box
        sx={{
          p: 7,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "36px",
          minHeight: "65vh",
        }}
      >
        <Typography className="main-title">Ta'lim turini tanlang</Typography>
        <Box className="flexer-box">
          <Box className="each-type-box" onClick={bachelor}>
            <Box className="child-box">
              <Typography className="font-box">Bakalavr</Typography>
              <img src={Maxsus} alt="logo" width={50} height={50} />
            </Box>
          </Box>

          <Box className="each-type-box" onClick={changeMaster}>
            <Box className="child-box">
              <Typography className="font-box">Magistratura</Typography>
              <img src={Magistr} alt="logo" width={50} height={50} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
export default Main;
