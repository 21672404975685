import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import Typography from "@mui/material/Typography";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import "./style.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="padding_2x">
      <div className="flex">
        <section className="flex-content padding_1x">
          <Typography color={"white"} variant={"h5"} gutterBottom>
            {t("call-center")}
          </Typography>
          <a href="tel://+998955052200" className={"number"}>
            +998955052200
          </a>
          <a href="tel://+998955053300" className={"number"}>
            +998955053300
          </a>
          <a href="tel://+998955054400" className={"number"}>
            +998955054400
          </a>
          <a href="#" className={"number"}>
            {t("location")}
          </a>
        </section>

        <section className="flex-content padding_1x">
          <Typography color={"white"} variant={"h5"} gutterBottom>
            {t("social-media")}
          </Typography>
          <a href="#" style={{ display: "flex", alignItems: "center" }}>
            <FacebookIcon sx={{ margin: "0 6px 0 0" }} />
            <Typography sx={{ fontSize: "16px" }}> Facebook</Typography>
          </a>
          <a href="#" style={{ display: "flex", alignItems: "center" }}>
            <YouTubeIcon sx={{ margin: "0 6px 0 0" }} />
            <Typography sx={{ fontSize: "16px" }}> Youtube</Typography>
          </a>
          <a href="#" style={{ display: "flex", alignItems: "center" }}>
            <TelegramIcon sx={{ margin: "0 6px 0 0" }} />
            <Typography sx={{ fontSize: "16px" }}> Telegram</Typography>
          </a>
        </section>

        <section className="flex-content padding_1x ">
          <Typography color={"white"} variant={"h5"} gutterBottom>
            {t("newsletter")}
          </Typography>
          <p>{t("sure-text")}</p>
          <fieldset className="footer_disable">
            <input
              type="email"
              name="newsletter"
              placeholder={t("email-location")}
            />
            <button className="btn btn_2" style={{ background: "#fdc632" }}>
              {t("subscribe")}
            </button>
          </fieldset>
        </section>
      </div>
      <div className="flex">
        <section
          className="flex-content padding_1x"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <p>© SAT 2022 - All rights reserved.</p>
        </section>
      </div>
    </footer>
  );
};
export default Footer;
