import React, { useEffect, useState, useTransition } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import MuiPhoneNumber from "material-ui-phone-number";

import instance from "../../api";
import imageLog from "../../components/images/02.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer/index.jsx";
import { Box, Typography } from "@mui/material";
import Header from "../../components/Header/index.jsx";
import {useTranslation} from "react-i18next";

function Number() {
  const {t}=useTranslation()
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const onSubmitHandlerPhone = async (event) => {
    if (value.length > 0) {
      try {
        await instance
          .post("/sign/user/check", JSON.stringify({ phone: value }), {
            headers: { "Content-Type": "application/json" },
          })
          .then((reqst) => {
            if (reqst.status === 200) {
              localStorage.setItem("token", `${reqst?.data?.data?.id}`);
              localStorage.setItem("number", value);
              toast.success(`${t('toast-success')}`);
              navigate("/code");
            }
          });
      } catch (err) {
        if (!err?.response) {
          toast.error("Internet aloqasi mavjud emas!");
        } else if (err.response?.status === 400) {
          toast.info("Telefon raqamingizni kiriting");
        } else if (err.response?.status === 401) {
          toast.error("Unathorized");
        } else if (err?.response?.data?.errorMessage === "invalid phone") {
          toast.error(`${t("wrong-number")}`);
        } else if (
          err?.response?.data?.errorMessage ===
          "iltimos bir ozdan so'ng qayta urinib ko'ring"
        ) {
          toast.info(`${t("toast-retry")}`);
        } else {
          toast.error("LogInda muammo");
        }
      }
    } else {
      toast.info("Iltimos, raqamingizni kiriting!");
    }
  };

  const enterKey = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      onSubmitHandlerPhone();
    }
  };

  return (
    <React.Fragment>
      <Header />
      <MDBContainer
        style={{
          padding: "70px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {" "}
        <Box className="wrapper-word">
          <Typography className="typed">
            {t('title')}
          </Typography>
        </Box>
        <MDBCard className="p-3">
          <MDBRow className="g-0 d-flex align-items-center">
            <MDBCol md="4">
              <MDBCardImage
                src={imageLog}
                alt="phone"
                className="rounded-t-5 rounded-tr-lg-0 p-5"
                width={1000}
                fluid
              />
            </MDBCol>
            <MDBCol md="8">
              <MDBCardBody>
                <h4 className="my-3 text-center fw-bold">
                  {t("number")}
                </h4>
                <MuiPhoneNumber
                  label={t("input-label")}
                  variant="filled"
                  defaultCountry={"uz"}
                  onlyCountries={["uz", "kz", "tm", "tj", "fi", "kg", "af"]}
                  disableAreaCodes
                  countryCodeEditable={false}
                  name="phone"
                  value={value}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  autoComplete={'off'}
                  onKeyUp={enterKey}
                />
                <MDBBtn
                  className="mt-3 w-100 py-2"
                  type="submit"
                  onClick={onSubmitHandlerPhone}
                >
                  {
                    t("send-btn")
                  }
                </MDBBtn>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
      <Footer />
    </React.Fragment>
  );
}

export default Number;
