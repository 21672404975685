import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router";

import information from "../../assets/My Information.png";
import contract from "../../assets/My Contract.png";
import Footer from "../../components/Footer/index.jsx";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";

const Cabinet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      <Box
        sx={{
          p: 7,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "36px",
        }}
      >
        <Typography
          variant={"h5"}
          sx={{ fontWeight: "600", textAlign: "center" }}
        >
          {t("my_cabinet")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "36px",
            flexWrap: "wrap",
          }}
        >
          <Card
            sx={{ minWidth: 345 }}
            className={"card-scale"}
            onClick={() => navigate("/mydata")}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                width={"280"}
                height={"280"}
                image={contract}
                alt="logo"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center", fontWeight: "500" }}
                >
                  {t("my-information")}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{ minWidth: 345 }}
            className={"card-scale"}
            onClick={() => navigate("/register")}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                width={"280"}
                height={"280"}
                image={information}
                alt="logo"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center", fontWeight: "500" }}
                >
                  {t("my-data")}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
export default Cabinet;
