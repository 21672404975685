import React, { useEffect, useState } from "react";
import Select from "react-select";
import { typeOfEducation } from "../../components/data/data.js";
import instance from "../../api/index.jsx";
import Spinner from "../../components/Spinner/index.jsx";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Box, Grid, IconButton, Stack, TextField, Tooltip, Typography} from "@mui/material";
import { Sheet, styled } from "@mui/joy";
import Button from "@mui/material/Button";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { useNavigate } from "react-router";

const Item = styled(Sheet)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.vars.palette.text.tertiary,
}));

export default function Ranking() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [students, setStudents] = useState(null);
  const [edu, setEdu] = useState("SIRTQI");
  const [fieldName, setFieldName] = useState("IQTISODIYOT (sirtqi)");
  const [eduName, setEduName] = useState("Bakalavr (Sirtqi)");
  const [opt, setOpt] = useState("30bcc50c-ec19-43ba-83ef-d5b8357e202f");
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    (async () => {
      await instance
        .get("field/get/list")
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await instance
        .get(`user/rating/${opt}`)
        .then((res) => setStudents(res.data))
        .catch((err) => console.log(err));
    })();
  }, [opt]);

  let rows = students?.data || [];

  const searchByName = () => {
    rows = rows.filter((el) =>
      el.fullName.toLowerCase().includes(fullName.trim().toLowerCase())
    );
    return rows;
  };
  searchByName();

  return (
    <Box p={3}>
      <Grid container spacing={1} sx={{ flexGrow: 1, mb: 2 }}>
        <Grid item xs={12} md={3}>
          <Item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title="Chiqish">
                <IconButton  sx={{ mr: 2 }} onClick={() => navigate(-1)}>
                  <FirstPageIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>

              <Typography variant={"h5"}>Talabalar reytingi</Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item>
            <Select
              options={typeOfEducation}
              placeholder={"Ta'lim turini tanlang"}
              onChange={(option) => {
                setEduName(option.label);
                setEdu(option.value);
              }}
            />
          </Item>
        </Grid>

        <Grid item xs={12} md={3}>
          <Item>
            <Select
              options={data?.data?.[edu].map((el) => ({
                value: el.fieldName,
                label: el.fieldName,
                id: el.id,
              }))}
              placeholder={"Ta'lim yo'nalishini tanlang"}
              onChange={(option) => {
                setFieldName(option.label);
                setOpt(option.id);
              }}
            />
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item>
            <Stack direction="row" gap={1}>
              <TextField
                size="small"
                placeholder="Ismi bilan..."
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                sx={{ width: "100%" }}
              />
              <Button variant="outlined" color="success">
                Qidirish
              </Button>
            </Stack>
          </Item>
        </Grid>
      </Grid>

      {students ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1000 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={16}># </TableCell>
                <TableCell>F.I.O</TableCell>
                <TableCell>Ta'lim turi</TableCell>
                <TableCell align="center">Ta'lim yo'nalishi</TableCell>
                <TableCell align="right">
                  To'plagan bali <ArrowUpwardIcon fontSize="small" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell width={16}>{i + 1}.</TableCell>
                  <TableCell component="th" scope="row">
                    {row.fullName}
                  </TableCell>
                  <TableCell>{eduName}</TableCell>
                  <TableCell align="center">{fieldName}</TableCell>
                  <TableCell align="right">{row.result}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Spinner />
      )}
    </Box>
  );
}
