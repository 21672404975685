import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

import instance from "../../api";
import imageLog from "../../components/images/02.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Footer from "../../components/Footer/index.jsx";
import "./style.css";
import { TextField } from "@mui/material";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
const REQUEST_URL_CODE = "/sign/send/code";

function Code() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [code, setCode] = useState("");
  const onsubmitHandlerCode = async (event) => {
    if (code.length > 0) {
      try {
        await instance
          .post(
            REQUEST_URL_CODE,
            JSON.stringify({
              code: code,
              id: `${localStorage.getItem("token")}`,
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((reqst) => {
            if (reqst.status === 200) {
              if (reqst?.data?.data?.action === "REGISTER") {
                localStorage.setItem(
                  "regesterId",
                  `${reqst?.data?.data?.registerId}`
                );
                navigate("/main");
              } else {
                localStorage.setItem(
                  "AccessToken",
                  reqst?.data?.data?.accessToken
                );
                localStorage.setItem(
                  "RefreshToken",
                  reqst?.data?.data?.refreshToken
                );
                navigate("/cabinet");
              }
            }
          });
      } catch (err) {
        if (err?.response?.data?.errorMessage === "invalid code") {
          toast.error(`${t("wrong-password")}`);
        }
      }
      setCode("");
    } else {
      toast.info(`${t("enterPassword")}`);
    }
  };

  const enterKey = async (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      await onsubmitHandlerCode();
    }
  };

  return (
    <React.Fragment>
      <Header />
      <MDBContainer
        style={{
          padding: "140px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <MDBCard className="p-3">
          <MDBRow className="g-0 d-flex align-items-center">
            <MDBCol md="4">
              <MDBCardImage
                src={imageLog}
                alt="phone"
                className="rounded-t-5 rounded-tr-lg-0 p-5"
                width={1000}
                fluid
              />
            </MDBCol>
            <MDBCol md="8">
              <MDBCardBody>
                <h4 className="my-4 text-center fw-bold">
                  {t("sms-text")}
                  {localStorage.getItem("number") && (
                    <p className="number-showing">
                      ({t("send-phone")}: {localStorage.getItem("number")})
                    </p>
                  )}
                </h4>

                <TextField
                  id="outlined-basic"
                  variant="filled"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                  label={t("label-sms")}
                  type="number"
                  autoComplete={"off"}
                  onKeyUp={(event) => enterKey(event)}
                />
                <MDBBtn
                  className="mb-4 w-100"
                  type="submit"
                  onClick={onsubmitHandlerCode}
                >
                  {t("send-btn")}
                </MDBBtn>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
      <Footer />
    </React.Fragment>
  );
}

export default Code;
