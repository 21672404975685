import React, { useContext, useEffect, useState } from "react";
import instance from "../../api/index.jsx";
import { Typography, Box, Grid, IconButton, Tooltip } from "@mui/material";

import "./style.css";
import Button from "@mui/material/Button";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useNavigate } from "react-router";
import Spinner from "../../components/Spinner/index.jsx";
import { useTranslation } from "react-i18next";
import Lang from "../../components/Lang/index.jsx";

const MyData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState(null);
  useEffect(() => {
    (async () => {
      instance
        .get("/user/contract/status", {
          headers: {
            "Content-Type": "application/json",
            "X-User-Token": localStorage.getItem("AccessToken"),
          },
        })
        .then((reqst) => setState(reqst?.data));
    })();
  }, []);
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
        },
        py: 2,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: {
            lg: 3,
            md: 2,
            sm: 2,
            xs: 2,
          },
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Tooltip title={t("exit")}>
            <IconButton sx={{ mr: 1 }} onClick={() => navigate("/cabinet")}>
              <FirstPageIcon sx={{ fontSize: "20px", color: "#123764" }} />
            </IconButton>
          </Tooltip>

          <Typography
            variant="h5"
            sx={{
              fontSize: {
                xs: "22px",
                sm: "24px",
                md: "25px",
                lg: "26px",
              },
            }}
            gutterBottom
            mb={0}
          >
            {t("my-information")}
          </Typography>
        </Box>
        <Lang condition="black" />
      </Box>

      {state ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box className="wrapper">
              <span>{t("target")}</span>
              <Typography>{state?.data?.target}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="wrapper">
              <span>{t("option")}</span>
              <Typography>{state?.data?.type}</Typography>
            </Box>
          </Grid>{" "}
          <Grid item xs={12} md={4}>
            <Box className="wrapper">
              <span>{t("sent-time")}</span>
              <Typography>{state?.data?.createdAt.slice(0, 10)}</Typography>
            </Box>
          </Grid>
          {state?.data?.acceptedAt ? (
            <Grid item xs={12} md={4}>
              <Box className="wrapper">
                <span>{t("recipient-time")}</span>
                <Typography>{state?.data?.acceptedAt.slice(0, 10)}</Typography>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} md={4}>
            <Box className="wrapper">
              <span>{t("recipient")}</span>
              <Typography>{state?.data?.recipient}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="wrapper">
              <span>{t("condition")}</span>

              {state?.data?.applicationStatus === "NEW" ? (
                <Typography sx={{ color: "rgb(249,192,4)" }}>
                  {" "}
                  {t("registered")}
                </Typography>
              ) : state?.data?.applicationStatus === "ACCEPTED" ? (
                <Typography sx={{ color: "rgb(46, 125, 50)" }}>
                  {" "}
                  {t("accepted")}
                </Typography>
              ) : state?.data?.applicationStatus === "EXAM" ? (
                <Typography sx={{ color: "rgb(46, 125, 50)" }}>
                  {" "}
                  {t("exam-suggestion")}
                </Typography>
              ) : (
                <Typography sx={{ color: "red" }}> {t("rejected")} </Typography>
              )}
            </Box>
          </Grid>
          {state?.data?.contractNumber ? (
            <Grid item xs={12} md={4}>
              <Box className="wrapper">
                <span>Shartnoma raqami</span>
                <Typography>{state?.data?.contractNumber}</Typography>
              </Box>
            </Grid>
          ) : null}
          {state?.data?.contract?.fullPath ? (
            <Grid item xs={12} md={4}>
              <Box className="wrapper">
                <Button href={state?.data?.contract?.fullPath} target="_blank">
                  shartnomani ko'rish
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Spinner />
      )}
    </Box>
  );
};
export default MyData;
