import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router";

import logo_ico from "../../assets/logo-ico.ico";
import Lang from "../Lang";
import { useTranslation } from "react-i18next";

const pages = [];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleNavigation = () => {
    return navigate("/ranking");
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={{ background: "#234C77" }}>
      <Container maxWidth="xl" sx={{ py: 1 }}>
        <Toolbar disableGutters>
          <img
            src={logo_ico}
            alt="logo"
            height={40}
            onClick={() => navigate(-1)}
            style={{
              margin: "0 16px 0 0",
            }}
          />

          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Lang />
            <Box>
              <Typography
                variant="subtitle1"
                color={"white"}
                gutterBottom
                onClick={handleNavigation}
                sx={{
                  transition: ".2s",
                  fontFamily: "monospace",
                  mb: 0,
                  fontWeight: 500,
                  "&:hover": {
                    cursor: "pointer",
                    transform: "scale(1.08)",
                  },
                }}
              >
                {t("result")}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
