import React, { createContext, useState } from "react";
import { useNavigate } from "react-router";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  let navigate = useNavigate();
  const [state, setState] = useState(false);
  const bachelor = () => {
    localStorage.setItem("state", `${state}`);
    navigate("/optional-bachelor");
  };
  const changeBachelor = () => {
    setState(true);
    localStorage.setItem("state", `${state}`);
    navigate("/bakalavr");
  };
  const changeBachelorKunduzgi = () => {
    localStorage.setItem("state", `${state}`);
    setState(true);
    navigate("/kunduzgi-bakalavr");
  };
  const changeMaster = () => {
    localStorage.setItem("state", `${state}`);
    setState(false);
    navigate("/magistr");
  };
  const changeSirqiBakalavr = () => {
    localStorage.setItem("state", `${state}`);
    setState(true);
    navigate("/sirtqi-bakalavr");
  };
  return (
    <DataContext.Provider
      value={{
        bachelor,
        changeMaster,
        changeBachelor,
        state,
        changeSirqiBakalavr,
        changeBachelorKunduzgi,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataContext;
