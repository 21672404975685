import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Tooltip } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import instance from "../../api/index.jsx";

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getData } from "../../redux/actions.jsx";

import "./style.css";
import Spinner from "../../components/Spinner/index.jsx";
import { useTranslation } from "react-i18next";
import Lang from "../../components/Lang";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import LanguageIcon from "@mui/icons-material/Language";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const Image = styled("img")({
  width: "95%",
});
const URL = "/user/me";

const Information = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [info, setInfo] = useState(null);
  const [state, setState] = useState([]);

  useEffect(() => {
    (async () => {
      await instance
        .get("/user/contract/status", {
          headers: {
            "Content-Type": "application/json",
            "X-User-Token": localStorage.getItem("AccessToken"),
          },
        })
        .then((reqst) => setState(reqst?.data));
    })();
  }, []);

  const navigateEdit = () => {
    return navigate("/update");
  };
  useEffect(() => {
    (async () => {
      await instance
        .get(URL, {
          headers: {
            "Content-Type": "application/json",
            "X-User-Token": localStorage.getItem("AccessToken"),
          },
        })
        .then((reqst) => {
          setInfo(reqst?.data?.data);
          dispatch(getData(reqst?.data?.data));
        })
        .catch((err) => console.log(err));
    })();
  }, []);

  return (
    <Box sx={{ minHeight: "100vh", width: "100vw" }}>
      <Box p={3}>
        <Box
          sx={{
            mb: {
              xs: 0,
              sm: 1,
              md: 2,
              lg: 3,
            },
          }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Tooltip title={t("exit")}>
              <IconButton sx={{ mr: 1 }} onClick={() => navigate("/cabinet")}>
                <FirstPageIcon sx={{ fontSize: "20px", color: "#123764" }} />
              </IconButton>
            </Tooltip>
            <Typography
              variant="h5"
              sx={{
                fontSize: {
                  xs: "22px",
                  sm: "24px",
                  md: "25px",
                  lg: "26px",
                },
              }}
              mb={0}
              gutterBottom
            >
              {t("my-data")}
            </Typography>
          </Box>
          {state?.data?.applicationStatus === "REJECTED" && (
            <Box
              sx={{
                display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
                alignItems: "center",
                gap: 2,
              }}
              className={"me-lg-3 "}
            >
              <Button
                color={"success"}
                variant={"outlined"}
                onClick={navigateEdit}
              >
                {t("editBtn")}
              </Button>
              <Lang condition={"black"} />
            </Box>
          )}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Tooltip title={t("exit")}>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>

            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={navigateEdit} disableRipple>
                <EditIcon />
                {t("editBtn")}
              </MenuItem>
              <MenuItem disableRipple>
                <LanguageIcon />
                <Lang condition={"black"} />
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>

        {info ? (
          <Box>
            <Grid container spacing={2} sx={{ py: 3 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    className={"image-user"}
                    alt={"user-image"}
                    src={info?.userInfo?.profilePhoto?.fullPath}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("student")}</span>
                      <Typography>
                        {info?.userInfo?.lastName +
                          " " +
                          info?.userInfo?.firstName +
                          " " +
                          info?.userInfo?.fatherName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("education-type")}</span>
                      <Typography>{info?.fieldInfo?.studyType}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("passport-info")}</span>
                      <Typography>
                        {info?.passportInfo?.docSerial +
                          " " +
                          info?.passportInfo?.docNumber}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("student-location")}</span>
                      <Typography>
                        {info?.passportInfo?.region +
                          ", " +
                          info?.passportInfo?.district +
                          ", " +
                          info?.passportInfo?.streetAndHome}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("birth-date")}</span>
                      <Typography>{info?.passportInfo?.birthDate}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("email")}</span>
                      <Typography>
                        {info?.userInfo?.email
                          ? info?.userInfo?.email
                          : "Email mavjud emas"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("phone-number")}</span>
                      <Typography>+{info?.userInfo?.mobilePhone}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <hr />

            <Box
              sx={{
                py: {
                  xs: 0,
                  md: 1,
                  lg: 3,
                },
                px: {
                  xs: 0,
                  md: 1,
                  lg: 3,
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xs: "20px",
                    md: "26px",
                    lg: "24px",
                  },
                  ml: 2,
                }}
                gutterBottom
              >
                {t("all-data")}
              </Typography>

              <Grid container spacing={2} sx={{ py: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("nation")}</span>
                    <Typography>{info?.passportInfo?.nationality}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("citizenship")}</span>
                    <Typography>{info?.passportInfo?.citizenship}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("passportIsGiven")}</span>
                    <Typography>{info?.passportInfo?.docGivenBy}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("diploma-info")}</span>
                    <Typography>
                      {info?.diplomaInfo?.diplomaSerial +
                        " " +
                        info?.diplomaInfo?.diplomaNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("gender")}</span>
                    <Typography>
                      {info?.passportInfo?.gender === "MALE"
                        ? t("male")
                        : t("female")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("finishedCompanyName")}</span>
                    <Typography>{info?.diplomaInfo?.muassasaTuri}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("registeredNumber")}</span>
                    <Typography>{info?.userInfo?.phone}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("finishedCompanyType")}</span>
                    <Typography>{info?.diplomaInfo?.muassasaNomi}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("passportGivenDate")}</span>
                    <Typography>{info?.passportInfo?.docGivenDate}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("finishedYear")}</span>
                    <Typography>
                      {info?.diplomaInfo?.graduatedYear}
                      {t("year")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("companyIsLocated")}</span>
                    <Typography>
                      {info?.diplomaInfo?.region +
                        " " +
                        info?.diplomaInfo?.district}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("isGraduated")}</span>
                    <Typography>{info?.diplomaInfo?.malumoti}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("contractPrice")}</span>
                    <Typography>
                      {info?.fieldInfo?.contractCost} (
                      {info?.fieldInfo?.contractCostInLetters})
                    </Typography>
                  </Box>
                </Grid>
                {info?.userInfo?.examPassed && (
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("isPassedExam")}</span>
                      <Typography>
                        {info?.userInfo?.examPassed ? t("yes") : t("no")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {info?.userInfo?.examResult && (
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <span>{t("examScore")}</span>
                      <Typography>{info?.userInfo?.examResult}</Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("educationPeriod")}</span>
                    <Typography>
                      {info?.fieldInfo?.duration}
                      {t("year")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("typeOfEducation")}</span>
                    <Typography>{info?.fieldInfo?.fieldType}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("fieldOfEducation")}</span>
                    <Typography>{info?.fieldInfo?.fieldName}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("educationLanguage")}</span>
                    <Typography>
                      {info?.fieldInfo?.language === "UZB" ? t("uzbLang") : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("registeredTime")}</span>
                    <Typography>{info?.userInfo?.cratedAt}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <span>{t("statusOfStudent")}</span>
                    <Typography>
                      {info?.userInfo?.zone === "YELLOW"
                        ? t("registered")
                        : info?.userInfo?.zone === "RED"
                        ? t("rejected")
                        : t("accepted")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <Button
                      href={info?.diplomaInfo?.diplomaAttachment?.fullPath}
                      target="_blank"
                    >
                      {t("copyDiploma")}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box">
                    <Button
                      href={info?.passportInfo?.passportAttachment?.fullPath}
                      target="_blank"
                    >
                      {t("copyPassport")}
                    </Button>
                  </Box>
                </Grid>
                {info?.diplomaInfo?.ieltsAttachment?.fullPath && (
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <Button
                        href={info?.diplomaInfo?.ieltsAttachment?.fullPath}
                        target="_blank"
                      >
                        {t("copyInternationalCertificate")}
                      </Button>
                    </Box>
                  </Grid>
                )}
                {info?.diplomaInfo?.tavsiyanomaAttachment?.fullPath && (
                  <Grid item xs={12} md={6}>
                    <Box className="box">
                      <Button
                        href={
                          info?.diplomaInfo?.tavsiyanomaAttachment?.fullPath
                        }
                        target="_blank"
                      >
                        {t("copyWorkingPaper")}
                      </Button>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  {info?.diplomaInfo?.mehnatDaftarAttachment?.fullPath && (
                    <Box className="box">
                      <Button
                        href={
                          info?.diplomaInfo?.mehnatDaftarAttachment?.fullPath
                        }
                        target="_blank"
                      >
                        {t("copyRecommendation")}
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Spinner />
        )}
      </Box>
    </Box>
  );
};
export default Information;
