import React from "react";
import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";

import NotFound from "./components/NotFound";
import Number from "./Pages/Log/number.jsx";
import Code from "./Pages/Log/code.jsx";
import Main from "./Pages/MainPage";
import { DataProvider } from "./context/dataContext";
import Bakalavr from "./views/Bakalavr/index.jsx";
import Magistr from "./views/Magistr/index.jsx";
import Information from "./Pages/Information/index.jsx";
import MyData from "./Pages/MyData/index.jsx";
import Cabinet from "./Pages/Cabinet/index.jsx";
import Update from "./Pages/Update/index.jsx";
import SirtqiBakalavr from "./views/SirtqiBakalavr";
import BakalavrKunduzgi from "./views/BakalavrKunduzgi/BakalavrKunduzgi";
import OptionBachelor from "./Pages/Bachelor/bachelor";
import Ranking from "./Pages/Ranking/index.jsx";
function App() {
  return (
    <DataProvider>
      <Routes>
        <Route path="/" element={<Number />} />
        <Route path="/code" element={<Code />} />
        <Route path="/main" element={<Main />} />
        <Route path="/optional-bachelor" element={<OptionBachelor />} />
        <Route path="/bakalavr" element={<Bakalavr />} />
        <Route path="/magistr" element={<Magistr />} />
        <Route path="/sirtqi-bakalavr" element={<SirtqiBakalavr />} />
        <Route path="/kunduzgi-bakalavr" element={<BakalavrKunduzgi />} />
        <Route path="/register" element={<Information />} />
        <Route path="/mydata" element={<MyData />} />
        <Route path="/cabinet" element={<Cabinet />} />
        <Route path="/update" element={<Update />} />
        <Route path="/ranking" element={<Ranking />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"/>
    </DataProvider>
  );
}

export default App;
