import uzb from "../../assets/flags/uzbekistan.png";
import rus from "../../assets/flags/russia.png";
export const data = [
  {
    country: "O'zb",
    lang: "uz",
    flag: `${uzb}`,
  },

  {
    country: "Рyс",
    lang: "ru",
    flag: `${rus}`,
  },
];
