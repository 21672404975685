import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MDBTextArea } from "mdb-react-ui-kit";
import Select from "react-select";
import { toast } from "react-toastify";

import "../index.css";

import instance from "../../api";
import {
  Andijon,
  Buxoro,
  Fargona,
  jins,
  Jizzax,
  Namangan,
  Navoiy,
  Qaraqalpaq,
  Qashqadaryo,
  Samarqand,
  viloyat,
  Sirdaryo,
  Surxandaryo,
  TashkentCity,
  TashkentRegion,
  Xorazm,
  country,
  millat,
  prime,
  middle_degree,
  middle,
  daraja,
} from "../../components/data/data.js";
import DataContext from "../../context/dataContext.jsx";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useNavigate } from "react-router";
import Lang from "../../components/Lang/index.jsx";
import { useTranslation } from "react-i18next";

// URLS-->
const REGISTER_URL = "/sign/register";
const PROFILE_PICTURE_URL = "/file/upload/profile-photo";
const DIPLOM_PICTURE_URL = "/file/upload/diploma";
const PASSPORT_PICTURE_URL = "/file/upload/passport";
const IELTS_COPY_URL = "/file/upload/ielts";
const FIELD_URL = "/field/get/list";

const BakalavrKunduzgi = () => {
  const { t } = useTranslation();
  const { state } = useContext(DataContext);
  let navigate = useNavigate();
  const [type, setType] = useState(null);
  const [fieldData, setFieldData] = useState([]);

  const [passportRegion, setPassportRegion] = useState("");
  const [diplomRegion, setDiplomRegion] = useState("");

  const [value, setValue] = useState(dayjs("2005-01-01"));
  const [docGivenDate, setDocGivenDate] = useState(dayjs("2021-01-01"));

  const [passportId, setPassportId] = useState([]);
  const [tavsiyanomaId, setTavsiyanomaId] = useState([]);
  const [diplomId, setDiplomId] = useState([]);
  const [profileId, setProfileId] = useState([]);
  const [fieldId, setFieldId] = useState("");
  const [ieltsId, setIeltsId] = useState([]);

  const [sex, setSex] = useState("MALE");
  const [citizenship, setCitizenship] = useState("");
  const [nationality, setNationality] = useState("");
  const [passportAddress, setPassportAddress] = useState("");
  const [passportLivePlace, setPassportLivePlace] = useState("");
  const [diplomLivePlace, setDiplomLivePlace] = useState("");
  const [diplomAddress, setDiplomAddress] = useState("");
  const [diplomNumber, setDiplomNumber] = useState("");
  const [diplomSeria, setDiplomSeria] = useState("");
  const [degree, setDegree] = useState("O'rta");
  const [educationType, setEducationType] = useState(null);

  const name = useRef();
  const surname = useRef();
  const middleName = useRef();
  const passportSeria = useRef();
  const passportIsGiven = useRef();
  const companyName = useRef();
  const phone = useRef();
  const email = useRef();
  const comment = useRef();
  const graduatedYear = useRef();
  const streetAndHome = useRef();
  const passportNumber = useRef();

  const turi = [];

  useEffect(() => {
    (async () => {
      const { data } = await instance.get(FIELD_URL, {
        headers: {
          "Content-type": "application/json",
        },
      });
      setFieldData(data?.data);
    })();
  }, []);
  const fieldValue = Object.values(fieldData)[3] || "";
  for (let i = 0; i < fieldValue.length; i++) {
    turi.push({
      value: fieldValue[i].fieldName,
      label: fieldValue[i].fieldName,
    });
  }

  const profilePicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await toast
        .promise(
          instance.post(PROFILE_PICTURE_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }),
          {
            pending: "Iltimos, kutib turing",
            success: "Muvaffaqiyatli yuklandi 👌",
          }
        )
        .then((reqst) => setProfileId(reqst));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta! 🤯");
      }
    }
  };
  const passportPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await toast
        .promise(
          instance.post(PASSPORT_PICTURE_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }),
          {
            pending: "Iltimos, kutib turing",
            success: "Muvaffaqiyatli yuklandi 👌",
          }
        )
        .then((reqst) => setPassportId(reqst));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta! 🤯");
      }
    }
  };
  const diplomPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await toast
        .promise(
          instance.post(DIPLOM_PICTURE_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }),
          {
            pending: "Iltimos, kutib turing",
            success: "Muvaffaqiyatli yuklandi 👌",
          }
        )
        .then((reqst) => setDiplomId(reqst));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta! 🤯");
      }
    }
  };
  const IeltsCopy = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await toast
        .promise(
          instance.post("/file/upload/ielts", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }),
          {
            pending: "Iltimos, kutib turing",
            success: "Muvaffaqiyatli yuklandi 👌",
          }
        )
        .then((reqst) => setIeltsId(reqst));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta! 🤯");
      }
    }
  };

  const handleSex = (selectedOptions) => {
    setSex(selectedOptions.value);
  };

  const handleCitizenship = (selectedOptions) => {
    setCitizenship(selectedOptions.value);
  };
  const handleNationality = (selectedOptions) => {
    setNationality(selectedOptions.value);
  };
  const handleEducationType = (selectedOptions) => {
    setEducationType(selectedOptions.value);
  };
  const handlePassportAddress = (selectedOptions) => {
    setPassportAddress(selectedOptions.value);
  };
  const handlePassportLivePlace = (selectedOptions) => {
    setPassportLivePlace(selectedOptions.value);
  };
  const handleDiplomAddress = (selectedOptions) => {
    setDiplomAddress(selectedOptions.value);
  };
  const handleDiplomLivePlace = (selectedOption) => {
    setDiplomLivePlace(selectedOption.value);
  };
  const handleDegree = (selectedOptions) => {
    setDegree(selectedOptions.value);
  };
  const findFieldId = (id) => {
    for (let i = 0; i < fieldValue.length; i++) {
      if (id.value === fieldValue[i]?.fieldName) {
        setFieldId(fieldValue[i]?.id);
      }
    }
  };
  useEffect(() => {
    if (degree === "Oliy") {
      setType(prime);
    } else if (degree === "O'rta") {
      setType(middle);
    } else {
      setType(middle_degree);
    }
  }, [degree]);
  useEffect(() => {
    if (passportLivePlace === "Andijon viloyati") {
      setPassportRegion(Andijon);
    } else if (passportLivePlace === "Qashqadaryo viloyati") {
      setPassportRegion(Qashqadaryo);
    } else if (passportLivePlace === "Qoraqalpog‘iston Respublikasi") {
      setPassportRegion(Qaraqalpaq);
    } else if (passportLivePlace === "Buxoro viloyati") {
      setPassportRegion(Buxoro);
    } else if (passportLivePlace === "Toshkent viloyati") {
      setPassportRegion(TashkentRegion);
    } else if (passportLivePlace === "Sirdaryo viloyati") {
      setPassportRegion(Sirdaryo);
    } else if (passportLivePlace === "Surxandaryo viloyati") {
      setPassportRegion(Surxandaryo);
    } else if (passportLivePlace === "Namangan viloyati") {
      setPassportRegion(Namangan);
    } else if (passportLivePlace === "Farg'ona viloyati") {
      setPassportRegion(Fargona);
    } else if (passportLivePlace === "Xorazm viloyati") {
      setPassportRegion(Xorazm);
    } else if (passportLivePlace === "Navoiy viloyati") {
      setPassportRegion(Navoiy);
    } else if (passportLivePlace === "Samarqand viloyati") {
      setPassportRegion(Samarqand);
    } else if (passportLivePlace === "Jizzax viloyati") {
      setPassportRegion(Jizzax);
    } else {
      setPassportRegion(TashkentCity);
    }
  }, [passportLivePlace]);
  useEffect(() => {
    if (diplomLivePlace === "Andijon viloyati") {
      setDiplomRegion(Andijon);
    } else if (diplomLivePlace === "Qashqadaryo viloyati") {
      setDiplomRegion(Qashqadaryo);
    } else if (diplomLivePlace === "Qoraqalpog‘iston Respublikasi") {
      setDiplomRegion(Qaraqalpaq);
    } else if (diplomLivePlace === "Buxoro viloyati") {
      setDiplomRegion(Buxoro);
    } else if (diplomLivePlace === "Toshkent viloyati") {
      setDiplomRegion(TashkentRegion);
    } else if (diplomLivePlace === "Sirdaryo viloyati") {
      setDiplomRegion(Sirdaryo);
    } else if (diplomLivePlace === "Surxandaryo viloyati") {
      setDiplomRegion(Surxandaryo);
    } else if (diplomLivePlace === "Namangan viloyati") {
      setDiplomRegion(Namangan);
    } else if (diplomLivePlace === "Farg'ona viloyati") {
      setDiplomRegion(Fargona);
    } else if (diplomLivePlace === "Xorazm viloyati") {
      setDiplomRegion(Xorazm);
    } else if (diplomLivePlace === "Navoiy viloyati") {
      setDiplomRegion(Navoiy);
    } else if (diplomLivePlace === "Samarqand viloyati") {
      setDiplomRegion(Samarqand);
    } else if (diplomLivePlace === "Jizzax viloyati") {
      setDiplomRegion(Jizzax);
    } else {
      setDiplomRegion(TashkentCity);
    }
  }, [diplomLivePlace]);

  const findType = (state) => {
    return state || localStorage.getItem("state") ? (
      <span style={{ fontSize: "18px", color: "#fdc632" }}>
        {" "}
        ({t("bachelor")})
      </span>
    ) : null;
  };

  const getDate = (date) => {
    let time = date.$d.toString();
    let month = time.slice(4, 7);
    let year = time.slice(11, 15);
    let day = time.slice(8, 10);
    if (month === "Jan") {
      month = "01";
    } else if (month === "Feb") {
      month = "02";
    } else if (month === "Mar") {
      month = "03";
    } else if (month === "Apr") {
      month = "04";
    } else if (month === "May") {
      month = "05";
    } else if (month === "Jun") {
      month = "06";
    } else if (month === "Jul") {
      month = "07";
    } else if (month === "Aug") {
      month = "08";
    } else if (month === "Sep") {
      month = "09";
    } else if (month === "Oct") {
      month = "10";
    } else if (month === "Nov") {
      month = "11";
    } else {
      month = "12";
    }

    return year + "-" + month + "-" + day;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (
      name.current.value.trim().length > 0 &&
      surname.current.value.trim().length > 0 &&
      middleName.current.value.trim().length > 0 &&
      phone.current.value.length > 0 &&
      companyName.current.value.length > 0 &&
      graduatedYear.current.value.length > 0 &&
      diplomLivePlace.length > 0 &&
      diplomAddress.length > 0 &&
      citizenship.length > 0 &&
      nationality.length > 0 &&
      passportLivePlace.length > 0 &&
      diplomSeria.trim().length > 0 &&
      diplomNumber.trim().length > 0 &&
      streetAndHome.current.value.trim().length > 0 &&
      passportSeria.current.value.trim().length > 0 &&
      passportNumber.current.value.length > 0 &&
      passportIsGiven.current.value.trim().length > 0 &&
      sex.length > 0 &&
      profileId?.data?.data?.id.length > 0 &&
      diplomId?.data?.data?.id.length > 0 &&
      passportId?.data?.data?.id.length > 0 &&
      fieldId.length > 0
    ) {
      const Data = {
        registerId: `${localStorage.getItem("regesterId")}`,
        data: {
          firstName: name.current.value.trim(),
          lastName: surname.current.value.trim(),
          fatherName: middleName.current.value.trim(),
          email: email.current.value.trim(),
          mobilePhone: phone.current.value.trim(),
          profilePhotoAttachmentId: profileId?.data?.data?.id,
          comment: comment.current.value.trim(),
        },
        diploma: {
          malumoti: degree,
          muassasaTuri: educationType,
          muassasaNomi: companyName.current.value.trim(),
          graduatedYear: graduatedYear.current.value,
          region: diplomLivePlace,
          district: diplomAddress,
          diplomaSerial: diplomSeria.trim(),
          diplomaNumber: diplomNumber,
          diplomaAttachmentId: diplomId?.data?.data?.id,
          tavsiyanomaAttachmentId: tavsiyanomaId?.data?.data?.id,
          ieltsAttachmentId: ieltsId?.data?.data?.id,
        },
        passport: {
          citizenship: citizenship,
          nationality: nationality,
          region: passportLivePlace,
          district: passportAddress,
          streetAndHome: streetAndHome.current.value.trim(),
          birthDate: getDate(value),
          docSerial: passportSeria.current.value.trim(),
          docNumber: passportNumber.current.value,
          docGivenBy: passportIsGiven.current.value.trim(),
          docGivenDate: getDate(docGivenDate),
          gender: sex,
          passportAttachmentId: passportId?.data?.data?.id,
        },
        field: {
          fieldId: fieldId,
        },
      };
      try {
        await instance
          .post(REGISTER_URL, JSON.stringify(Data), {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((reqst) => {
            if (reqst.status === 200) {
              toast.success(`${t("successfullyCreated")}`);
              localStorage.setItem(
                "AccessToken",
                reqst?.data?.data?.accessToken
              );
              localStorage.setItem(
                "RefreshToken",
                reqst?.data?.data?.refreshToken
              );
              navigate("/register");
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.info(`${t("toastEmpty")}`);
    }
  };

  return (
    <Box>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            mb: 2,
            display: { xs: "flex", sm: "none" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={t("exit")}>
            <IconButton sx={{ mr: 1 }} onClick={() => navigate(-1)}>
              <FirstPageIcon sx={{ fontSize: "20px", color: "#234c77" }} />
            </IconButton>
          </Tooltip>
          <Lang condition={"black"} />
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Tooltip title={t("exit")}>
              <IconButton
                sx={{ mr: 1, display: { xs: "none", sm: "flex" } }}
                onClick={() => navigate(-1)}
              >
                <FirstPageIcon sx={{ fontSize: "20px", color: "#234c77" }} />
              </IconButton>
            </Tooltip>

            <Typography variant={"h5"}>
              {t("personalInfo")}
              {findType(state)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <Lang condition={"black"} />
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              label={t("name")}
              autoComplete={"off"}
              inputRef={name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              label={t("surname")}
              autoComplete={"off"}
              required
              inputRef={surname}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              autoComplete={"off"}
              label={t("fatherName")}
              inputRef={middleName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                label={t("birthDate")}
                openTo="year"
                inputFormat="DD/MM/YYYY"
                views={["year", "month", "day"]}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size="small"
                    required
                    autoComplete={"off"}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <span className="info-title">{t("choosingGender")} *</span>
            <Select
              options={jins}
              placeholder={"Jinsni tanlang..."}
              onChange={handleSex}
              value={jins.find((option) => option.value === sex)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <span className="info-title">{t("profileImg")} *</span>
            <input
              type="file"
              className="form-control form-control-sm profil-input"
              aria-label="file example"
              required
              accept="image/*"
              onChange={profilePicture}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          {t("passportInfo")}
          {findType(state)}
        </Typography>
        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6} sx={{ zIndex: "999" }}>
            <Select
              options={country}
              placeholder={t("citizenship")}
              classNamePrefix="my-custom-react-select"
              onChange={handleCitizenship}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "998" }}>
            <Select
              required
              options={millat}
              placeholder={t("nation")}
              onChange={handleNationality}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "997" }}>
            <Select
              required
              options={viloyat}
              placeholder={t("region")}
              onChange={handlePassportLivePlace}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "996" }}>
            <Select
              required
              options={passportRegion}
              placeholder={t("area")}
              onChange={handlePassportAddress}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                label={t("passportGivenDate")}
                openTo="year"
                inputFormat="DD/MM/YYYY"
                views={["year", "month", "day"]}
                value={docGivenDate}
                onChange={(newValue) => {
                  setDocGivenDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size={"small"}
                    required
                    autoComplete={"off"}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              label={t("streetAndHome")}
              helperText={t("currentLivingPlace")}
              inputRef={streetAndHome}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="text"
                  required
                  inputRef={passportSeria}
                  label={t("passportSeria")}
                  inputProps={{
                    maxLength: 2,
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="number"
                  required
                  inputRef={passportNumber}
                  label={t("passportNumber")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              inputRef={passportIsGiven}
              label={t("passportIsWho")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <span className="info-title">{t("passportFile")} *</span>
            <input
              type="file"
              className="form-control form-control-sm profil-input"
              aria-label="file example"
              required
              accept="application/pdf,image/*"
              onChange={passportPicture}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          {t("diplomaInfo")}
          {findType(state)}
        </Typography>
        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6} sx={{ zIndex: "999" }}>
            <Select
              options={daraja}
              placeholder={t("choosingDegree")}
              onChange={handleDegree}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "998" }}>
            <Select
              placeholder={t("typeOfDegree")}
              options={type}
              onChange={handleEducationType}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              inputRef={companyName}
              label={t("finishedEducation")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="number"
              required
              inputRef={graduatedYear}
              label={t("finishedYear")}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ zIndex: "997" }}>
            <Select
              options={viloyat}
              placeholder={t("diplomaRegion")}
              onChange={handleDiplomLivePlace}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "996" }}>
            <Select
              options={diplomRegion}
              placeholder={t("diplomaArea")}
              onChange={handleDiplomAddress}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <span className="info-title">{t("diplomaFile")} *</span>

            <input
              type="file"
              className="form-control form-control-sm profil-input"
              aria-label="file example"
              required
              accept="application/pdf,image/*"
              onChange={diplomPicture}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <span className="info-title">{t("internationalFile")}</span>
            <input
              type="file"
              className="form-control form-control-sm profil-input"
              aria-label="file example"
              accept="application/pdf,image/*"
              onChange={IeltsCopy}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="text"
                  required
                  value={diplomSeria}
                  onChange={(e) => setDiplomSeria(e.target.value)}
                  label={t("diplomaSeria")}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item xs={8}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="number"
                  value={diplomNumber}
                  required
                  onChange={(e) => setDiplomNumber(e.target.value)}
                  label={t("diplomaNumber")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          {t("choosingField")} {findType(state)}
        </Typography>

        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6} sx={{ zIndex: "994" }}>
            <Select
              placeholder={`${t("choosingField")}...`}
              options={turi}
              onChange={findFieldId}
              required
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          {t("connectionInfo")}
          {findType(state)}
        </Typography>

        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="number"
              inputRef={phone}
              required
              label={t("phoneNumber")}
              placeholder="+998-"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="email"
              label={t("email")}
              inputRef={email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBTextArea
              label={t("commentary")}
              id="textAreaExample"
              rows={4}
              inputRef={comment}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 0, pb: 5, display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{ px: 4, py: 1 }}
          type={"submit"}
          onClick={onSubmitHandler}
        >
          {t("submittingBtn")}
        </Button>
      </Box>
    </Box>
  );
};

export default BakalavrKunduzgi;
