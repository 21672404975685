import React from "react";
import Cookies from "js-cookie";

import i18next from "i18next";

import "./language.css";
import { data } from "../data/localization";

function Lang({ condition = "white" }) {
  const currentLanguageCode = Cookies.get("i18next") || "ru";
  const currentLanguage = data.find((l) => l.lang === currentLanguageCode);

  return (
    <div className="dropdown">
      <button
        className={`btn btn-light shadow-1 dropdown-toggle ${
          condition === "white" ? "btn-translate" : "btn-translate-black"
        }`}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src={currentLanguage.flag} alt="flag" width={16} height={14} />
        <span className="text-translate ml-2">{currentLanguage.country}</span>
      </button>

      <div
        className={`dropdown-menu bg-light ${
          condition === "white"
            ? "translate-dropdown"
            : "translate-dropdown-black"
        }`}
        aria-labelledby="dropdownMenuButton"
      >
        {data.map(({ lang, flag, country }) => (
          <div
            className="dropdown-item px-3 py-2"
            key={lang}
            onClick={() => i18next.changeLanguage(lang)}
          >
            <img src={flag} alt="flag" width={17} height={15} />
            <span className="ml-2">{country}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Lang;
