import {combineReducers,applyMiddleware, legacy_createStore} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import GetData from "./reducers";
const reducers = combineReducers({
getOneUserData:GetData
})

const store = legacy_createStore(reducers,  composeWithDevTools(applyMiddleware(thunk))
)
export default store