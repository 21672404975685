import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Button, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MDBTextArea, MDBValidationItem } from "mdb-react-ui-kit";
import Select from "react-select";
import { toast } from "react-toastify";
import "../../views/index.css";
import {
  Andijon,
  Buxoro,
  Fargona,
  jins,
  Jizzax,
  Namangan,
  Navoiy,
  Qaraqalpaq,
  Qashqadaryo,
  Samarqand,
  middle_degree,
  middle,
  prime,
  viloyat,
  daraja,
  Sirdaryo,
  Surxandaryo,
  TashkentCity,
  TashkentRegion,
  Xorazm,
  country,
  millat,
} from "../../components/data/data.js";
import { useNavigate } from "react-router";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../api/index.jsx";
import { getData } from "../../redux/actions.jsx";

// URLS-->
const PROFILE_PICTURE_URL = "/file/upload/profile-photo";
const DIPLOM_PICTURE_URL = "/file/upload/diploma";
const PASSPORT_PICTURE_URL = "/file/upload/passport";
const TAVSIYANOMA_PICTURE_URL = "/file/upload/tavsiyanoma";
const IELTS_COPY_URL = "/file/upload/ielts";
const MEHNAT_DAFTARCHA_URL = "/file/upload/mehnat/daftar";

export default function Update() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.getOneUserData);
  const [fieldData, setFieldData] = useState([]);
  const [type, setType] = useState("");
  const [passportRegion, setPassportRegion] = useState("");
  const [diplomRegion, setDiplomRegion] = useState("");

  const [value, setValue] = useState(dayjs(userInfo?.passportInfo?.birthDate));
  const [docGivenDate, setDocGivenDate] = useState(
    dayjs(userInfo?.passportInfo?.docGivenDate)
  );

  const [passportId, setPassportId] = useState(
    userInfo?.passportInfo?.passportAttachment?.id || null
  );
  const [tavsiyanomaId, setTavsiyanomaId] = useState(
    userInfo?.diplomaInfo?.tavsiyanomaAttachment?.id || null
  );
  const [diplomId, setDiplomId] = useState(
    userInfo?.diplomaInfo?.diplomaAttachment?.id || null
  );
  const [ieltsId, setIeltsId] = useState(
    userInfo?.userInfo?.ieltsAttachment?.id || null
  );
  const [profileId, setProfileId] = useState(
    userInfo?.userInfo?.profilePhoto?.id || null
  );
  const [daftarId, setDaftarId] = useState(
    userInfo?.userInfo?.mehnatDaftarAttachment?.id || null
  );
  const [fieldId, setFieldId] = useState(userInfo?.fieldInfo?.fieldId);
  const [sex, setSex] = useState(userInfo?.passportInfo?.gender);

  const [degree, setDegree] = useState(userInfo?.diplomaInfo?.malumoti);
  const [educationType, setEducationType] = useState(
    userInfo?.diplomaInfo?.muassasaTuri
  );
  const [citizenship, setCitizenship] = useState(
    userInfo?.passportInfo?.citizenship
  );
  const [nationality, setNationality] = useState(
    userInfo?.passportInfo?.nationality
  );
  const [passportAddress, setPassportAddress] = useState(
    userInfo?.passportInfo?.district
  );
  const [passportLivePlace, setPassportLivePlace] = useState(
    userInfo?.passportInfo?.region
  );
  const [diplomLivePlace, setDiplomLivePlace] = useState(
    userInfo?.diplomaInfo?.region
  );
  const [diplomAddress, setDiplomAddress] = useState(
    userInfo?.diplomaInfo?.district
  );
  const [name, setName] = useState(userInfo?.userInfo?.firstName || "");
  const [middleName, setMiddleName] = useState(
    userInfo?.userInfo?.fatherName || ""
  );
  const [surname, setSurname] = useState(userInfo?.userInfo?.lastName || "");
  const [passportIsGiven, setPassportIsGiven] = useState(
    userInfo?.passportInfo?.docGivenBy || ""
  );
  const [companyName, setCompanyName] = useState(
    userInfo?.diplomaInfo?.muassasaNomi || ""
  );
  const [phone, setPhone] = useState(userInfo?.userInfo?.mobilePhone);

  const [diplomSeria, setDiplomSeria] = useState(
    userInfo?.diplomaInfo?.diplomaSerial || ""
  );
  const [graduatedYear, setGraduatedYear] = useState(
    userInfo?.diplomaInfo?.graduatedYear || ""
  );
  const [streetAndHome, setStreetAndHome] = useState(
    userInfo?.passportInfo?.streetAndHome || ""
  );
  const [passportNumber, setPassportNumber] = useState(
    userInfo?.passportInfo?.docNumber || ""
  );
  const [diplomNumber, setDiplomNumber] = useState(
    userInfo?.diplomaInfo?.diplomaNumber || ""
  );
  const [passportSeria, setPassportSeria] = useState(
    userInfo?.passportInfo?.docSerial || ""
  );
  const email = useRef();
  const comment = useRef();

  const turi = [];

  useEffect(() => {
    (async () => {
      await instance
        .get("/user/me", {
          headers: {
            "Content-Type": "application/json",
            "X-User-Token": localStorage.getItem("AccessToken"),
          },
        })
        .then((reqst) => {
          dispatch(getData(reqst?.data?.data));
        })
        .catch((err) => console.log(err));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await instance.get("/field/get/list", {
        headers: {
          "Content-type": "application/json",
        },
      });
      setFieldData(data?.data);
    })();
  }, []);

  const fieldValue =
    userInfo?.fieldInfo?.studyType === "BAKALAVR"
      ? Object.values(fieldData)[0] || ""
      : Object.values(fieldData)[1] || "";

  for (let i = 0; i < fieldValue.length; i++) {
    turi.push({
      value: fieldValue[i].fieldName,
      label: fieldValue[i].fieldName,
    });
  }

  const profilePicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await instance
        .post(PROFILE_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setProfileId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const passportPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await instance
        .post(PASSPORT_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setPassportId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const diplomPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await instance
        .post(DIPLOM_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setDiplomId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const tavsiyanomaPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      await instance
        .post(TAVSIYANOMA_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setTavsiyanomaId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const IeltsCopy = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await instance
        .post(IELTS_COPY_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setIeltsId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const mehnatDaftar = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await instance
        .post(MEHNAT_DAFTARCHA_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((reqst) => {
          setDaftarId(reqst?.data?.data?.id);
        });
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };

  const handleDegree = (selectedOptions) => {
    setDegree(selectedOptions.value);
  };
  const handleSex = (selectedOptions) => {
    setSex(selectedOptions.value);
  };
  const handleEducationType = (selectedOptions) => {
    setEducationType(selectedOptions.value);
  };
  const handleCitizenship = (selectedOptions) => {
    setCitizenship(selectedOptions.value);
  };
  const handleNationality = (selectedOptions) => {
    setNationality(selectedOptions.value);
  };
  const handlePassportAddress = (selectedOptions) => {
    setPassportAddress(selectedOptions.value);
  };
  const handlePassportLivePlace = (selectedOptions) => {
    setPassportLivePlace(selectedOptions.value);
  };
  const handleDiplomAddress = (selectedOptions) => {
    setDiplomAddress(selectedOptions.value);
  };
  const handleDiplomLivePlace = (selectedOption) => {
    setDiplomLivePlace(selectedOption.value);
  };

  const findFieldId = (id) => {
    for (let i = 0; i < fieldValue.length; i++) {
      if (id.value === fieldValue[i]?.fieldName) {
        setFieldId(fieldValue[i]?.id);
      }
    }
  };

  useEffect(() => {
    if (degree === "Oliy") {
      setType(prime);
    } else if (degree === "O'rta") {
      setType(middle);
    } else {
      setType(middle_degree);
    }
  }, [degree]);
  useEffect(() => {
    if (passportLivePlace === "Andijon viloyati") {
      setPassportRegion(Andijon);
    } else if (passportLivePlace === "Qashqadaryo viloyati") {
      setPassportRegion(Qashqadaryo);
    } else if (passportLivePlace === "Qoraqalpog‘iston Respublikasi") {
      setPassportRegion(Qaraqalpaq);
    } else if (passportLivePlace === "Buxoro viloyati") {
      setPassportRegion(Buxoro);
    } else if (passportLivePlace === "Toshkent viloyati") {
      setPassportRegion(TashkentRegion);
    } else if (passportLivePlace === "Sirdaryo viloyati") {
      setPassportRegion(Sirdaryo);
    } else if (passportLivePlace === "Surxandaryo viloyati") {
      setPassportRegion(Surxandaryo);
    } else if (passportLivePlace === "Namangan viloyati") {
      setPassportRegion(Namangan);
    } else if (passportLivePlace === "Farg'ona viloyati") {
      setPassportRegion(Fargona);
    } else if (passportLivePlace === "Xorazm viloyati") {
      setPassportRegion(Xorazm);
    } else if (passportLivePlace === "Navoiy viloyati") {
      setPassportRegion(Navoiy);
    } else if (passportLivePlace === "Samarqand viloyati") {
      setPassportRegion(Samarqand);
    } else if (passportLivePlace === "Jizzax viloyati") {
      setPassportRegion(Jizzax);
    } else {
      setPassportRegion(TashkentCity);
    }
  }, [passportLivePlace]);
  useEffect(() => {
    if (diplomLivePlace === "Andijon viloyati") {
      setDiplomRegion(Andijon);
    } else if (diplomLivePlace === "Qashqadaryo viloyati") {
      setDiplomRegion(Qashqadaryo);
    } else if (diplomLivePlace === "Qoraqalpog‘iston Respublikasi") {
      setDiplomRegion(Qaraqalpaq);
    } else if (diplomLivePlace === "Buxoro viloyati") {
      setDiplomRegion(Buxoro);
    } else if (diplomLivePlace === "Toshkent viloyati") {
      setDiplomRegion(TashkentRegion);
    } else if (diplomLivePlace === "Sirdaryo viloyati") {
      setDiplomRegion(Sirdaryo);
    } else if (diplomLivePlace === "Surxandaryo viloyati") {
      setDiplomRegion(Surxandaryo);
    } else if (diplomLivePlace === "Namangan viloyati") {
      setDiplomRegion(Namangan);
    } else if (diplomLivePlace === "Farg'ona viloyati") {
      setDiplomRegion(Fargona);
    } else if (diplomLivePlace === "Xorazm viloyati") {
      setDiplomRegion(Xorazm);
    } else if (diplomLivePlace === "Navoiy viloyati") {
      setDiplomRegion(Navoiy);
    } else if (diplomLivePlace === "Samarqand viloyati") {
      setDiplomRegion(Samarqand);
    } else if (diplomLivePlace === "Jizzax viloyati") {
      setDiplomRegion(Jizzax);
    } else {
      setDiplomRegion(TashkentCity);
    }
  }, [diplomLivePlace]);

  const getDate = (date) => {
    let time = date.$d.toString();
    let month = time.slice(4, 7);
    let year = time.slice(11, 15);
    let day = time.slice(8, 10);
    if (month === "Jan") {
      month = "01";
    } else if (month === "Feb") {
      month = "02";
    } else if (month === "Mar") {
      month = "03";
    } else if (month === "Apr") {
      month = "04";
    } else if (month === "May") {
      month = "05";
    } else if (month === "Jun") {
      month = "06";
    } else if (month === "Jul") {
      month = "07";
    } else if (month === "Aug") {
      month = "08";
    } else if (month === "Sep") {
      month = "09";
    } else if (month === "Oct") {
      month = "10";
    } else if (month === "Nov") {
      month = "11";
    } else {
      month = "12";
    }

    return year + "-" + month + "-" + day;
  };
  const ErrorFunction = (name) => {
    if (!name) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (
      name.trim().length > 0 &&
      surname.trim().length > 0 &&
      middleName.trim().length > 0 &&
      phone.length > 0 &&
      degree.length > 0 &&
      educationType.length > 0 &&
      companyName.trim().length > 0 &&
      graduatedYear &&
      diplomLivePlace.length > 0 &&
      diplomAddress.length > 0 &&
      diplomSeria.trim().length > 0 &&
      diplomNumber.length > 0 &&
      citizenship.length > 0 &&
      nationality.length > 0 &&
      passportLivePlace.length > 0 &&
      streetAndHome.trim().length > 0 &&
      passportSeria.trim().length > 0 &&
      passportNumber.length > 0 &&
      passportIsGiven.trim().length > 0 &&
      sex.length > 0 &&
      profileId &&
      diplomId &&
      passportId &&
      tavsiyanomaId &&
      daftarId &&
      fieldId
    ) {
      const Data = {
        userId: `${localStorage.getItem("AccessToken")}`,
        data: {
          firstName: name.trim(),
          lastName: surname.trim(),
          fatherName: middleName.trim(),
          email: email.current.value.trim(),
          mobilePhone: phone.value.trim(),
          profilePhotoAttachmentId: profileId,
          comment: comment.current.value.trim(),
        },
        diploma: {
          malumoti: degree,
          muassasaTuri: educationType,
          muassasaNomi: companyName.trim(),
          graduatedYear: graduatedYear,
          region: diplomLivePlace,
          district: diplomAddress,
          diplomaSerial: diplomSeria.trim(),
          diplomaNumber: diplomNumber,
          diplomaAttachmentId: diplomId,
          tavsiyanomaAttachmentId: tavsiyanomaId,
          ieltsAttachmentId: ieltsId,
          mehnatDaftarAttachmentId: daftarId,
        },
        passport: {
          citizenship: citizenship,
          nationality: nationality,
          region: passportLivePlace,
          district: passportAddress,
          streetAndHome: streetAndHome.trim(),
          birthDate: getDate(value),
          docSerial: passportSeria.trim(),
          docNumber: passportNumber.trim(),
          docGivenBy: passportIsGiven.trim(),
          docGivenDate: getDate(docGivenDate),
          gender: sex,
          passportAttachmentId: passportId,
        },
        field: {
          fieldId: fieldId,
        },
      };
      try {
        await instance
          .post("/user/update", JSON.stringify(Data), {
            headers: {
              "Content-Type": "application/json",
              "X-User-Token": localStorage.getItem("AccessToken"),
            },
          })
          .then((reqst) => {
            if (reqst.status === 200) {
              toast.success("Muvaffaqiyatli amalga oshirildi!");
              navigate(-1);
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      ` toast.info("Ma'lumotlar to'liq to'ldirilmagan!");`;
    }
  };

  const onSubmitHandlerMagistr = async (event) => {
    event.preventDefault();
    if (
      name.trim().length > 0 &&
      surname.trim().length > 0 &&
      middleName.trim().length > 0 &&
      phone.length > 0 &&
      degree.length > 0 &&
      educationType.length > 0 &&
      companyName.trim().length > 0 &&
      graduatedYear &&
      diplomLivePlace.length > 0 &&
      diplomAddress.length > 0 &&
      diplomSeria.trim().length > 0 &&
      diplomNumber.trim().length > 0 &&
      citizenship.length > 0 &&
      nationality.length > 0 &&
      passportLivePlace.length > 0 &&
      streetAndHome.trim().length > 0 &&
      passportSeria.trim().length > 0 &&
      passportNumber.trim().length > 0 &&
      passportIsGiven.trim().length > 0 &&
      sex.length > 0 &&
      profileId &&
      diplomId &&
      passportId &&
      fieldId
    ) {
      const Data = {
        userId: `${localStorage.getItem("AccessToken")}`,
        data: {
          firstName: name.trim(),
          lastName: surname.trim(),
          fatherName: middleName.trim(),
          email: email.current.value.trim(),
          mobilePhone: phone.trim(),
          profilePhotoAttachmentId: profileId,
          comment: comment.current.value.trim(),
        },
        diploma: {
          malumoti: degree,
          muassasaTuri: educationType,
          muassasaNomi: companyName.trim(),
          graduatedYear: graduatedYear,
          region: diplomLivePlace,
          district: diplomAddress,
          diplomaSerial: diplomSeria.trim(),
          diplomaNumber: diplomNumber.trim(),
          diplomaAttachmentId: diplomId,
          ieltsAttachmentId: ieltsId,
          tavsiyanomaAttachmentId: tavsiyanomaId,
        },
        passport: {
          citizenship: citizenship,
          nationality: nationality,
          region: passportLivePlace,
          district: passportAddress,
          streetAndHome: streetAndHome.trim(),
          birthDate: getDate(value),
          docSerial: passportSeria.trim(),
          docNumber: passportNumber.trim(),
          docGivenBy: passportIsGiven.trim(),
          docGivenDate: getDate(docGivenDate),
          gender: sex,
          passportAttachmentId: passportId,
        },
        field: {
          fieldId: fieldId,
        },
      };
      try {
        await instance
          .post("/user/update", JSON.stringify(Data), {
            headers: {
              "Content-Type": "application/json",
              "X-User-Token": localStorage.getItem("AccessToken"),
            },
          })
          .then((reqst) => {
            if (reqst.status === 200) {
              toast.success("Muvaffaqiyatli amalga oshirildi!");
              navigate(-1);
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.info("Ma'lumotlar to'liq to'ldirilmagan!");
    }
  };
  const isError = {
    control: (styles) => ({ ...styles, border: "1px solid #d32f2f" }),
  };
  const colorStyles = {
    control: (styles) => ({ ...styles }),
  };
  return (
    <div>
      <Box sx={{ p: 3 }}>
        <Box
         display={'flex'}
         alignItems={'center'}
         mb={1}
        >
        <Tooltip title={"Chiqish"}>
          <IconButton sx={{ mr: 1 }} onClick={() => navigate(-1)}>
            <FirstPageIcon />
          </IconButton>
        </Tooltip>
          <Typography variant={"h5"}>
            Shaxsiy ma'lumotlarni tahrirlash
            <span style={{ fontSize: "18px", color: "#fdc632" }}>
              {userInfo?.fieldInfo?.studyType === "BAKALAVR"
                ? " (Bakalaviriat)"
                : " (Magistratura)"}
            </span>
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              label="Ism"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={ErrorFunction(name)}
              helperText={
                ErrorFunction(name) && "Iltimos, ma'lumotingizni kiriting"
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              label="Familiya"
              required
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              error={ErrorFunction(surname)}
              helperText={
                ErrorFunction(surname) && "Iltimos, ma'lumotingizni kiriting"
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              label="Otasining ismi"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              error={ErrorFunction(middleName)}
              helperText={
                ErrorFunction(middleName) && "Iltimos, ma'lumotingizni kiriting"
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                label="Tug'ilgan sanasi"
                openTo="year"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size="small"
                    error={ErrorFunction(value)}
                    helperText={
                      ErrorFunction(value) &&
                      "Iltimos, tug'ilgan sanangizni kiriting"
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            {sex ? (
              <span>Iltimos, jinsingizni belgilang *</span>
            ) : (
              <span className="text-error">
                Iltimos, jinsingizni belgilang *
              </span>
            )}
            <Select
              styles={sex ? colorStyles : isError}
              options={jins}
              placeholder={
                userInfo?.passportInfo?.gender === "MALE" ? "Erkak" : "Ayol"
              }
              onChange={handleSex}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {userInfo?.userInfo?.profilePhoto?.fullPath ? (
              <span>
                Fotosurat 3.5 X 4.5, 600 X 800 piksel 5 mb dan oshmagan holda (
                png, jpg, jpeg ) *
              </span>
            ) : (
              <span className="text-error">
                Fotosurat 3.5 X 4.5, 600 X 800 piksel 5 mb dan oshmagan holda (
                png, jpg, jpeg ) *
              </span>
            )}
              <input
                type="file"
                className={
                  userInfo?.userInfo?.profilePhoto?.fullPath
                    ? "form-control form-control-sm profil-input"
                    : "form-control-error form-control form-control-sm profil-input"
                }
                aria-label="file example"
                required
                accept="image/*"
                onChange={profilePicture}
              />
              {userInfo?.userInfo?.profilePhoto?.fullPath && (
                <Button
                  href={userInfo?.userInfo?.profilePhoto?.fullPath}
                  className={"text-subtitle"}
                  target="_blank"
                >
                  File: {userInfo?.userInfo?.profilePhoto?.originalName}
                </Button>
              )}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          Passport ma'lumotlar tahrirlash
          <span style={{ fontSize: "18px", color: "#fdc632" }}>
            {userInfo?.fieldInfo?.studyType === "BAKALAVR"
              ? " (Bakalaviriat)"
              : " (Magistratura)"}
          </span>
        </Typography>
        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6} sx={{ zIndex: "999" }}>
            {citizenship ? (
              <span>Fuqoroligi *</span>
            ) : (
              <span className="text-error">Fuqoroligi *</span>
            )}
            <Select
              options={country}
              placeholder={userInfo?.passportInfo?.citizenship}
              styles={citizenship ? colorStyles : isError}
              onChange={handleCitizenship}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "998" }}>
            {nationality ? (
              <span>Millati *</span>
            ) : (
              <span className="text-error">Millati *</span>
            )}
            <Select
              styles={nationality ? colorStyles : isError}
              options={millat}
              placeholder={userInfo?.passportInfo?.nationality}
              onChange={handleNationality}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "997" }}>
            {passportLivePlace ? (
              <span>Yashash hududi *</span>
            ) : (
              <span className="text-error">Yashash hududi *</span>
            )}

            <Select
              styles={passportLivePlace ? colorStyles : isError}
              options={viloyat}
              placeholder={userInfo?.passportInfo?.region}
              onChange={handlePassportLivePlace}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "996" }}>
            {passportAddress ? (
              <span>Tuman *</span>
            ) : (
              <span className="text-error">Tuman *</span>
            )}
            <Select
              styles={passportAddress ? colorStyles : isError}
              options={passportRegion}
              placeholder={userInfo?.passportInfo?.district}
              onChange={handlePassportAddress}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                label="Passport berilgan sanasi"
                openTo="year"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                value={docGivenDate}
                onChange={(newValue) => {
                  setDocGivenDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size={"small"}
                    error={ErrorFunction(docGivenDate)}
                    helperText={
                      ErrorFunction(docGivenDate) &&
                      "Iltimos, passport berilgan sanasini kiriting"
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              label="Ko'cha nomi, uy va xonadon raqami"
              value={streetAndHome}
              onChange={(e) => setStreetAndHome(e.target.value)}
              error={ErrorFunction(streetAndHome)}
              helperText={
                ErrorFunction(streetAndHome)
                  ? "Iltimos, ma'lumotingizni kiriting"
                  : "(Hozirda ro'yxatga turgan joyingizni kiriting)"
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="text"
                  required
                  label="Passport seriayasi"
                  value={passportSeria}
                  onChange={(e) => setPassportSeria(e.target.value)}
                  error={ErrorFunction(passportSeria)}
                  helperText={
                    ErrorFunction(passportSeria) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
              <Grid item xs={8}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="number"
                  required
                  label="Passport raqami"
                  value={passportNumber}
                  onChange={(e) => setPassportNumber(e.target.value)}
                  error={ErrorFunction(passportNumber)}
                  helperText={
                    ErrorFunction(passportNumber) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              label="Kim tomonidan berilgan"
              value={passportIsGiven}
              onChange={(e) => setPassportIsGiven(e.target.value)}
              error={ErrorFunction(passportIsGiven)}
              helperText={
                ErrorFunction(passportIsGiven) &&
                "Iltimos, ma'lumotingizni kiriting"
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {userInfo?.passportInfo?.passportAttachment?.fullPath ? (
              <span className={'info-title'}>
                Passport yoki guvohnomani PDF formatda (rangli tasvirda, 5 mb
                dan oshmagan holda) *
              </span>
            ) : (
              <span className="info-title-error">
                Passport yoki guvohnomani PDF formatda (rangli tasvirda, 5 mb
                dan oshmagan holda) *
              </span>
            )}
              <input
                type="file"
                className={
                  userInfo?.passportInfo?.passportAttachment?.fullPath
                    ? "form-control form-control-sm profil-input"
                    : "form-control-error form-control form-control-sm profil-input"
                }
                aria-label="file example"
                required
                accept="application/pdf"
                onChange={passportPicture}
              />
              {userInfo?.passportInfo?.passportAttachment?.fullPath && (
                <Button
                  href={userInfo?.passportInfo?.passportAttachment?.fullPath}
                  className={"text-subtitle"}
                  target="_blank"
                >
                  File:{" "}
                  {userInfo?.passportInfo?.passportAttachment?.originalName}
                </Button>
              )}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          Diplom ma'lumotlar tahrirlash
          <span style={{ fontSize: "18px", color: "#fdc632" }}>
            {userInfo?.fieldInfo?.studyType === "BAKALAVR"
              ? " (Bakalaviriat)"
              : " (Magistratura)"}
          </span>
        </Typography>

        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6} sx={{ zIndex: "999" }}>
            {degree ? (
              <span>Ma'lumotini tanlang *</span>
            ) : (
              <span className="text-error">Ma'lumotini tanlang *</span>
            )}
            <Select
              styles={degree ? colorStyles : isError}
              options={daraja}
              placeholder={userInfo?.diplomaInfo?.malumoti}
              onChange={handleDegree}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "998" }}>
            {educationType ? (
              <span>Muassasa turi *</span>
            ) : (
              <span className="text-error">Muassasa turi *</span>
            )}
            <Select
              styles={educationType ? colorStyles : isError}
              placeholder={userInfo?.diplomaInfo?.muassasaTuri}
              options={type}
              onChange={handleEducationType}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="text"
              required
              label="Muassasa nomi"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              error={ErrorFunction(companyName)}
              helperText={
                ErrorFunction(companyName) &&
                "Iltimos, ma'lumotingizni kiriting"
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="number"
              required
              label="Tugatgan yili"
              value={graduatedYear}
              onChange={(e) => setGraduatedYear(e.target.value)}
              error={ErrorFunction(graduatedYear)}
              helperText={
                ErrorFunction(graduatedYear) &&
                "Iltimos, ma'lumotingizni kiriting"
              }
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ zIndex: "997" }}>
            {diplomLivePlace ? (
              <span>Tugatgan muassasa joylashgan viloyat *</span>
            ) : (
              <span className="text-error">
                Tugatgan muassasa joylashgan viloyat *
              </span>
            )}
            <Select
              styles={diplomLivePlace ? colorStyles : isError}
              options={viloyat}
              placeholder={userInfo?.diplomaInfo?.region}
              onChange={handleDiplomLivePlace}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ zIndex: "996" }}>
            {diplomAddress ? (
              <span>Tuman *</span>
            ) : (
              <span className="text-error">Tuman *</span>
            )}
            <Select
              styles={diplomAddress ? colorStyles : isError}
              options={diplomRegion}
              placeholder={userInfo?.diplomaInfo?.district}
              onChange={handleDiplomAddress}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {userInfo?.diplomaInfo?.diplomaAttachment?.fullPath ? (
              <span className={'info-title'}>
                Diplom yoki attestatni yuklash ( 5 mb dan oshmagan holda) *
              </span>
            ) : (
              <span className="info-title-error">
                Diplom yoki attestatni yuklash ( 5 mb dan oshmagan holda) *
              </span>
            )}

              <input
                type="file"
                className={
                  userInfo?.diplomaInfo?.diplomaAttachment?.fullPath
                    ? "form-control form-control-sm profil-input"
                    : "form-control-error form-control form-control-sm profil-input"
                }
                aria-label="file example"
                required
                accept="application/pdf,image/*"
                onChange={diplomPicture}
              />
              {userInfo?.diplomaInfo?.diplomaAttachment?.fullPath && (
                <Button
                  href={userInfo?.diplomaInfo?.diplomaAttachment?.fullPath}
                  className={"text-subtitle"}
                  target="_blank"
                >
                  File: {userInfo?.diplomaInfo?.diplomaAttachment?.originalName}
                </Button>
              )}
          </Grid>
          {userInfo?.fieldInfo?.studyType === "BAKALAVR" &&
          userInfo?.fieldInfo?.fieldType === "MAXSUS_SIRTQI" ? (
            <Grid item xs={12} md={6}>
              {userInfo?.diplomaInfo?.tavsiyanomaAttachment?.fullPath ? (
                <span className={'info-title'}>Tavsiyanomani yuklash (5 mb dan oshmagan holda) *</span>
              ) : (
                <span className="info-title-error">
                  Tavsiyanomani yuklash (5 mb dan oshmagan holda) *
                </span>
              )}
                <input
                  type="file"
                  className={
                    userInfo?.diplomaInfo?.tavsiyanomaAttachment?.fullPath
                      ? "form-control form-control-sm profil-input"
                      : "form-control-error form-control form-control-sm profil-input"
                  }
                  aria-label="file example"
                  required
                  accept="application/pdf,image/*"
                  onChange={tavsiyanomaPicture}
                />
                {userInfo?.diplomaInfo?.tavsiyanomaAttachment?.fullPath && (
                  <Button
                    href={
                      userInfo?.diplomaInfo?.tavsiyanomaAttachment?.fullPath
                    }
                    target="_blank"
                    className={"text-subtitle"}
                  >
                    File:{" "}
                    {userInfo?.diplomaInfo?.tavsiyanomaAttachment?.originalName}
                  </Button>
                )}
            </Grid>
          ) : null}

          {userInfo?.fieldInfo?.studyType === "BAKALAVR" &&
          userInfo?.fieldInfo?.fieldType === "MAXSUS_SIRTQI" ? (
            <Grid item xs={12} md={6}>
              {userInfo?.diplomaInfo?.mehnatDaftarAttachment?.fullPath ? (
                <span className={'info-title'}>
                  Mehnat daftarchasini nusxasini yuklash ( 5 mb dan oshmagan
                  holda) *
                </span>
              ) : (
                <span className="info-title-error">
                  Mehnat daftarchasini nusxasini yuklash ( 5 mb dan oshmagan
                  holda) *
                </span>
              )}

                <input
                  type="file"
                  className={
                    userInfo?.diplomaInfo?.mehnatDaftarAttachment?.fullPath
                      ? "form-control form-control-sm profil-input"
                      : "form-control-error form-control form-control-sm profil-input"
                  }
                  aria-label="file example"
                  required
                  accept="application/pdf"
                  onChange={mehnatDaftar}
                />
                {userInfo?.diplomaInfo?.mehnatDaftarAttachment?.fullPath && (
                  <Button
                    href={
                      userInfo?.diplomaInfo?.mehnatDaftarAttachment?.fullPath
                    }
                    target="_blank"
                    className={"text-subtitle"}
                  >
                    File:{" "}
                    {
                      userInfo?.diplomaInfo?.mehnatDaftarAttachment
                        ?.originalName
                    }
                  </Button>
                )}
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <span className="info-title">
              Xalqaro darajadagi sertifikatni yuklash (IELTS,TOEFL..., 5 mb dan
              oshmagan holda)
            </span>

              <input
                type="file"
                className="form-control form-control-sm profil-input"
                aria-label="file example"
                accept="application/pdf,image/*"
                onChange={IeltsCopy}
              />
              {userInfo?.diplomaInfo?.ieltsAttachment?.fullPath && (
                <Button
                  href={userInfo?.diplomaInfo?.ieltsAttachment?.fullPath}
                  target="_blank"
                  className={"text-subtitle"}
                >
                  File: {userInfo?.diplomaInfo?.ieltsAttachment?.originalName}
                </Button>
              )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="text"
                  required
                  label="Diplom yoki attestat seriasi"
                  inputProps={{ maxLength: 3 }}
                  value={diplomSeria}
                  onChange={(e) => setDiplomSeria(e.target.value)}
                  error={ErrorFunction(diplomSeria)}
                  helperText={
                    ErrorFunction(diplomSeria) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
              <Grid item xs={8}>
                {" "}
                <TextField
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  type="number"
                  required
                  label="Diplom yoki attestat raqami"
                  value={diplomNumber}
                  onChange={(e) => setDiplomNumber(e.target.value)}
                  error={ErrorFunction(diplomNumber)}
                  helperText={
                    ErrorFunction(diplomNumber) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          Ta'lim yo'nalishini tanlash
          <span style={{ fontSize: "18px", color: "#fdc632" }}>
            {userInfo?.fieldInfo?.studyType === "BAKALAVR"
              ? " (Bakalaviriat)"
              : " (Magistratura)"}
          </span>
        </Typography>

        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6} sx={{ zIndex: "994" }}>
            <Select
              placeholder={userInfo?.fieldInfo?.fieldName}
              options={turi}
              onChange={findFieldId}
              styles={fieldId ? colorStyles : isError}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography variant={"h5"}>
          Bog'lanish uchun ma'lumotlarni tahrirlash
          <span style={{ fontSize: "18px", color: "#fdc632" }}>
            {userInfo?.fieldInfo?.studyType === "BAKALAVR"
              ? " (Bakalaviriat)"
              : " (Magistratura)"}
          </span>
        </Typography>

        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="number"
              required
              label="Telefon raqam"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={ErrorFunction(phone)}
              helperText={
                ErrorFunction(phone) && "Iltimos, telefon raqamingizni kiriting"
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-size-small"
              size="small"
              fullWidth
              type="email"
              label="email"
              defaultValue={userInfo?.userInfo?.email}
              inputRef={email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBTextArea
              label="Izoh qoldiring..."
              id="textAreaExample"
              rows={4}
              inputRef={comment}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 0, pb: 5, display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{ px: 4, py: 1 }}
          onClick={
            userInfo?.fieldInfo?.studyType === "BAKALAVR" &&
            userInfo?.fieldInfo?.fieldType === "MAXSUS_SIRTQI"
              ? onSubmitHandler
              : onSubmitHandlerMagistr
          }
        >
          Ma'lumotlarni yuborish
        </Button>
      </Box>
    </div>
  );
}
