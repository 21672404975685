import {GET_DATA} from "./types";

const defaultValue = {
    page:0,
    userInfo:[]
}
const GetData = (state = defaultValue,action) =>{

switch (action.type) {
    case GET_DATA:
        return {
            ...state,
            userInfo: action.payload
        }
    default:
        return state
}
}
export default GetData;