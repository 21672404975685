const Qaraqalpaq = [
  {
    value: "Amudaryo tumani",
    label: "Amudaryo tumani",
  },
  {
    value: "Beruniy tumani",
    label: "Beruniy tumani",
  },
  {
    value: "Bo'zatov tumani",
    label: "Bo'zatov tumani",
  },
  {
    value: "Kegayli tumani",
    label: "Kegayli tumani",
  },
  {
    value: "Taxiatosh shahri",
    label: "Taxiatosh shahri",
  },
  {
    value: "Qonliko‘l tumani",
    label: "Qonliko‘l tumani",
  },

  {
    value: "Qorao‘zak tumani",
    label: "Qorao‘zak tumani",
  },
  {
    value: "Qo‘ng‘irot tumani",
    label: "Qo‘ng‘irot tumani",
  },
  {
    value: "Mo‘ynoq tumani",
    label: "Mo‘ynoq tumani",
  },
  {
    value: "Nukus tumani",
    label: "Nukus tumani",
  },
  {
    value: "Nukus shahri",
    label: "Nukus shahri",
  },

  {
    value: "Taxtako‘pir tumani",
    label: "Taxtako‘pir tumani",
  },
  {
    value: "To‘rtko‘l tumani",
    label: "To‘rtko‘l tumani",
  },
  {
    value: "Xo‘jayli tumani",
    label: "Xo‘jayli tumani",
  },
  {
    value: "Chimboy tumani",
    label: "Chimboy tumani",
  },

  {
    value: "Shumanay tumani",
    label: "Shumanay tumani",
  },
  {
    value: "Ellikqal‘a tumani",
    label: "Ellikqal‘a tumani",
  },
];
const Andijon = [
  {
    value: "Andijon shahri",
    label: "Andijon shahri",
  },
  {
    value: "Andijon tumani",
    label: "Andijon tumani",
  },
  {
    value: "Asaka tumani",
    label: "Asaka tumani",
  },
  {
    value: "Asaka shahri",
    label: "Asaka shahri",
  },
  {
    value: "Shaxrixon tumani",
    label: "Shaxrixon tumani",
  },
  {
    value: "Baliqchi tumani",
    label: "Baliqchi tumani",
  },
  {
    value: "Buloqboshi tumani",
    label: "Buloqboshi tumani",
  },
  {
    value: "Bo‘z tumani",
    label: "Bo‘z tumani",
  },
  {
    value: "Jalaquduq tumani",
    label: "Jalaquduq tumani",
  },
  {
    value: "Izbosgan tumani",
    label: "Izbosgan tumani",
  },
  {
    value: "Qorasuv tumani",
    label: "Qorasuv tumani",
  },
  {
    value: "Qo‘rg‘ontepa tumani",
    label: "Qo‘rg‘ontepa tumani",
  },
  {
    value: "Marhamat tumani",
    label: "Marhamat tumani",
  },
  {
    value: "Oltinko‘l tumani",
    label: "Oltinko‘l tumani",
  },
  {
    value: "Paxtaobod tumani",
    label: "Paxtaobod tumani",
  },
  {
    value: "Ulug‘nor tumani",
    label: "Ulug‘nor tumani",
  },
  {
    value: "Xonabod tumani",
    label: "Xonabod tumani",
  },
  {
    value: "Xonabod shahri",
    label: "Xonabod shahri",
  },
  {
    value: "Xo'jabod tumani",
    label: "Xo'jabod tumani",
  },
];
const Qashqadaryo = [
  {
    value: "Qarshi tumani",
    label: "Qarshi tumani",
  },
  {
    value: "G‘uzor tumani",
    label: "G‘uzor tumani",
  },
  {
    value: "Dehqonobod tumani",
    label: "Dehqonobod tumani",
  },
  {
    value: "Qamashi tumani",
    label: "Qamashi tumani",
  },
  {
    value: "Qarshi shahri",
    label: "Qarshi shahri",
  },
  {
    value: "Kasbi tumani",
    label: "Kasbi tumani",
  },
  {
    value: "Kitob tumani",
    label: "Kitob tumani",
  },
  {
    value: "Koson tumani",
    label: "Koson tumani",
  },
  {
    value: "Mirishkor tumani",
    label: "Mirishkor tumani",
  },
  {
    value: "Muborak tumani",
    label: "Muboraki tumani",
  },
  {
    value: "Nishon tumani",
    label: "Nishon tumani",
  },
  {
    value: "Chiroqchi tumani",
    label: "Chiroqchi tumani",
  },
  {
    value: "Shahrisabz tumani",
    label: "Shahrisabz tumani",
  },
  {
    value: "Shahrisabz shahri",
    label: "Shahrisabz shahri",
  },
  {
    value: "Yakkabog tumani",
    label: "Yakkabog tumani",
  },
  {
    value: "Ko'kdala tumani",
    label: "Ko'kdala tumani",
  },
];
const Buxoro = [
  {
    value: "Buxoro shahri",
    label: "Buxoro shahri",
  },
  {
    value: "Buxoro tumani",
    label: "Buxoro tumani",
  },
  {
    value: "Vobkent tumani",
    label: "Vobkent tumani",
  },
  {
    value: "Olot tumani",
    label: "Olot tumani",
  },
  {
    value: "Kogon tumani",
    label: "Kogon tumani",
  },
  {
    value: "Kogon shahri",
    label: "Kogon shahri",
  },
  {
    value: "G‘ijduvon tumani",
    label: "G‘ijduvon tumani",
  },
  {
    value: "Jondor tumani",
    label: "Jondor tumani",
  },
  {
    value: "Qorako'l tumani",
    label: "Qorako'l tumani",
  },
  {
    value: "Peshku shahri",
    label: "Peshku shahri",
  },
  {
    value: "Romitan shahri",
    label: "Romitan shahri",
  },
  {
    value: "Shofirkon tumani",
    label: "Shofirkon tumani",
  },
];
const Jizzax = [
  {
    value: "Arnasoy tumani",
    label: "Arnasoy tumani",
  },
  { value: "Baxmal tumani", label: "Baxmal   tumani" },
  {
    value: "G‘allaorol tumani",
    label: "G‘allaorol tumani",
  },
  {
    value: "Do‘stlik tumani",
    label: "Do‘stlik tumani",
  },
  {
    value: "Mirzacho'l tumani",
    label: "Mirzacho'l tumani",
  },
  {
    value: "Sh.Rashidov tumani",
    label: "Sh.Rashidov tumani",
  },
  {
    value: "Jizzax tumani",
    label: "Jizzax tumani",
  },
  {
    value: "Jizzax shahri",
    label: "Jizzax shahri",
  },
  {
    value: "Zarbdor tumani",
    label: "Zarbdor tumani",
  },
  {
    value: "Zafarobod tumani",
    label: "Zafarobod tumani",
  },
  {
    value: "Zomin tumani",
    label: "Zomin tumani",
  },
  {
    value: "Paxtakor tumani",
    label: "Paxtakor tumani",
  },
  {
    value: "Forish tumani",
    label: "Forish tumani",
  },
  {
    value: "Yangiobod tumani",
    label: "Yangiobod tumani",
  },
];
const Navoiy = [
  {
    value: "Nurota tumani",
    label: "Nurota tumani",
  },
  {
    value: "G‘ozg‘on shahri",
    label: "G‘ozg‘on shahri",
  },
  {
    value: "Zarafshon shahri",
    label: "Zarafshon shahri",
  },
  {
    value: "Karmana tumani",
    label: "Karmana tumani",
  },
  {
    value: "Qiziltepa tumani",
    label: "Qiziltepa tumani",
  },
  {
    value: "Konimex tumani",
    label: "Konimex tumani",
  },
  {
    value: "Navbahor tumani",
    label: "Navbahor tumani",
  },
  {
    value: "Navoiy shahri",
    label: "Navoiy shahri",
  },
  {
    value: "Tomdi tumani",
    label: "Tomdi tumani",
  },
  {
    value: "Uchquduq tumani",
    label: "Uchquduq tumani",
  },
  {
    value: "Xatirchi tumani",
    label: "Xatirchi tumani",
  },
];
const Namangan = [
  {
    value: "Pop tumani",
    label: "Pop tumani",
  },
  {
    value: "Davlatobod tumani",
    label: "Davlatobod tumani",
  },
  {
    value: "Chortoq tumani",
    label: "Chortoq tumani",
  },
  {
    value: "Kosonsoy tumani",
    label: "Kosonsoy tumani",
  },
  {
    value: "Mingbuloq tumani",
    label: "Mingbuloq tumani",
  },
  {
    value: "Namangan shahri",
    label: "Namangan shahri",
  },
  {
    value: "Namangan tumani",
    label: "Namangan tumani",
  },
  {
    value: "Norin tumani",
    label: "Norin tumani",
  },
  {
    value: "To'raqo'rg'on tumani",
    label: "To'raqo'rg'on tumani",
  },
  {
    value: "Uychi tumani",
    label: "Uychi tumani",
  },
  {
    value: "Uchqo‘rg‘on tumani",
    label: "Uchqo‘rg‘on tumani",
  },
  {
    value: "Chortoq tumani",
    label: "Chortoq tumani",
  },
  {
    value: "Chust tumani",
    label: "Chust tumani",
  },
  {
    value: "Yangiqo‘rg‘on tumani",
    label: "Yangiqo‘rg‘on tumani",
  },
];
const Samarqand = [
  {
    value: "Samarqand shahri",
    label: "Samarqand shahri",
  },
  {
    value: "Samarqand tumani",
    label: "Samarqand tumani",
  },
  {
    value: "Bulung'ur tumani",
    label: "Bulung'ur tumani",
  },
  {
    value: "Jomboy tumani",
    label: "Jomboy tumani",
  },
  {
    value: "Ishtixon tumani",
    label: "Ishtixon tumani",
  },
  {
    value: "Kattaqo'rg'on shahri",
    label: "Kattaqo'rg'on shahri",
  },
  {
    value: "Kattaqo'rg'on tumani",
    label: "Kattaqo'rg'on tumani",
  },
  {
    value: "Qo‘shrabot tumani",
    label: "Qo‘shrabot tumani",
  },
  {
    value: "Narpay tumani",
    label: "Narpay tumani",
  },
  {
    value: "Nurabod tumani",
    label: "Nurabod tumani",
  },
  {
    value: "Oqdaryo tumani",
    label: "Oqdaryo tumani",
  },
  {
    value: "Pastarg‘om tumani",
    label: "Pastarg‘om tumani",
  },
  {
    value: "Paxtachi tumani",
    label: "Paxtachi tumani",
  },
  {
    value: "Toyloq tumani",
    label: "Toyloq tumani",
  },
  {
    value: "Urgut tumani",
    label: "Urgut tumani",
  },
  {
    value: "Payariq tumani",
    label: "Payariq tumani",
  },
];
const Surxandaryo = [
  {
    value: "Uzun tumani",
    label: "Uzun tumani",
  },
  {
    value: "Denov tumani",
    label: "Denov tumani",
  },
  {
    value: "Bandixon tumani",
    label: "Bandixon tumani",
  },
  {
    value: "Angor tumani",
    label: "Angor tumani",
  },
  {
    value: "Boysun tumani",
    label: "Boysun tumani",
  },
  {
    value: "Jarqo'rg'on tumani",
    label: "Jarqo'rg'on tumani",
  },
  {
    value: "Qiziriq tumani",
    label: "Qiziriq tumani",
  },
  {
    value: "Qumqo'rg'on tumani",
    label: "Qumqo'rg'on tumani",
  },
  {
    value: "Muzrabot tumani",
    label: "Muzrabot tumani",
  },
  {
    value: "Oltinsoy tumani",
    label: "Oltinsoy tumani",
  },
  {
    value: "Sariosiyo tumani",
    label: "Sariosiyo tumani",
  },
  {
    value: "Termiz shahri",
    label: "Termiz shahri",
  },
  {
    value: "Termiz tumani",
    label: "Termiz tumani",
  },
  {
    value: "Sherobod tumani",
    label: "Sherobod tumani",
  },
  {
    value: "Sho'rchi tumani",
    label: "Sho'rchi tumani",
  },
];
const Sirdaryo = [
  {
    value: "Guliston tumani",
    label: "Guliston tumani",
  },
  {
    value: "Guliston shahri",
    label: "Guliston shahri",
  },
  {
    value: "Boyovut tumani",
    label: "Boyovut tumani",
  },
  {
    value: "Sayxunobod tumani",
    label: "Sayxunobod tumani",
  },
  {
    value: "Mirzaobod tumani",
    label: "Mirzaobod tumani",
  },
  {
    value: "Oqoltin tumani",
    label: "Oqoltin tumani",
  },
  {
    value: "Sardoba tumani",
    label: "Sardoba tumani",
  },
  {
    value: "Sirdaryo tumani",
    label: "Sirdaryo tumani",
  },
  {
    value: "Yangiyer tumani",
    label: "Yangiyer tumani",
  },
  {
    value: "Shirin shahri",
    label: "Shirin shahri",
  },
  {
    value: "Xavos tumani",
    label: "Xavos tumani",
  },
];
const TashkentCity = [
  {
    value: "Chilonzor tumani",
    label: "Chilonzor tumani",
  },
  {
    value: "Bektemir tumani",
    label: "Bektemir tumani",
  },
  {
    value: "Mirzo Ulug'bek tumani",
    label: "Mirzo Ulug'bek tumani",
  },
  {
    value: "Mirobod tumani",
    label: "Mirobod tumani",
  },
  {
    value: "Olmazor tumani",
    label: "Olmazor tumani",
  },
  {
    value: "Sirg'ali tumani",
    label: "Sirg'ali tumani",
  },
  {
    value: "Uchtepa tumani",
    label: "Uchtepa tumani",
  },
  {
    value: "Yashnobod tumani",
    label: "Yashnobod tumani",
  },
  {
    value: "Shayxontohur tumani",
    label: "Shayxontohur tumani",
  },
  {
    value: "Yunusobod tumani",
    label: "Yunusobod tumani",
  },
  {
    value: "Yakkasaroy tumani",
    label: "Yakkasaroy tumani",
  },
  {
    value: "Yangihayot tumani",
    label: "Yangihayot tumani",
  },
];
const TashkentRegion = [
  {
    value: "Angren shahri",
    label: "Angren shahri",
  },
  {
    value: "Bekobod tumani",
    label: "Bekobod tumani",
  },
  {
    value: "Bekobod shahri",
    label: "Bekobod shahri",
  },
  {
    value: "Bo‘ka tumani",
    label: "Bo‘ka tumani",
  },
  {
    value: "Bo‘stonliq tumani",
    label: "Bo‘stonliq tumani",
  },
  {
    value: "Zangiota tumani",
    label: "Zangiota tumani",
  },
  {
    value: "Qibray tumani",
    label: "Qibray tumani",
  },
  {
    value: "Quyichirchiq tumani",
    label: "Quyichirchiq tumani",
  },
  {
    value: "Oqqo‘rg‘on tumani",
    label: "Oqqo‘rg‘on tumani",
  },
  {
    value: "Ohangaron tumani",
    label: "Ohangaron tumani",
  },
  {
    value: "Parkent tumani",
    label: "Parkent tumani",
  },
  {
    value: "Olmaliq shahri",
    label: "Olmaliq shahri",
  },
  {
    value: "Piskent tumani",
    label: "Piskent tumani",
  },
  {
    value: "O‘rtachirchiq tumani",
    label: "O‘rtachirchiq tumani",
  },
  {
    value: "Chinoz tumani",
    label: "Chinoz tumani",
  },
  {
    value: "Chirchiq shahri",
    label: "Chirchiq shahri",
  },
  {
    value: "Yuqorichirchiq tumani",
    label: "Yuqorichirchiq tumani",
  },
  {
    value: "Yangiyo‘l tumani",
    label: "Yangiyo‘l tumani",
  },
  {
    value: "Ohangaron shahri",
    label: "Ohangaron shahri",
  },
  {
    value: "Yangiyo‘l shahri",
    label: "Yangiyo‘l shahri",
  },
  {
    value: "Toshkent tumani",
    label: "Toshkent tumani",
  },
];
const Xorazm = [
  {
    value: "Bog‘ot tumani",
    label: "Bog‘ot tumani",
  },
  {
    value: "Gurlan tumani",
    label: "Gurlan tumani",
  },
  {
    value: "Qo‘shko‘pir tumani",
    label: "Qo‘shko‘pir tumani",
  },
  {
    value: "Urganch tumani",
    label: "Urganch tumani",
  },
  {
    value: "Urganch shahri",
    label: "Urganch shahri",
  },
  {
    value: "Xiva shahri",
    label: "Xiva shahri",
  },
  {
    value: "Xiva tumani",
    label: "Xiva tumani",
  },
  {
    value: "Xazorasp tumani",
    label: "Xazorasp tumani",
  },
  {
    value: "Xonqa tumani",
    label: "Xonqa tumani",
  },
  {
    value: "Shavot tumani",
    label: "Shavot tumani",
  },
  {
    value: "Yangiariq tumani",
    label: "Yangiariq tumani",
  },
  {
    value: "Yangibozor tumani",
    label: "Yangibozor tumani",
  },
  {
    value: "Do'stlik shahri",
    label: "Do'stlik shahri",
  },
  {
    value: "Tuproqqala tumani",
    label: "Tuproqqala tumani",
  },
];
const Fargona = [
  {
    value: "Beshariq tumani",
    label: "Beshariq tumani",
  },
  {
    value: "Bog‘dod tumani",
    label: "Bog‘dod tumani",
  },
  {
    value: "Buvayda tumani",
    label: "Buvayda tumani",
  },
  {
    value: "Dang‘ara tumani",
    label: "Dang‘ara tumani",
  },
  {
    value: "Yozyovon tumani",
    label: "Yozyovon tumani",
  },
  {
    value: "Quva tumani",
    label: "Quva tumani",
  },
  {
    value: "Quvasoy shahri",
    label: "Quvasoy shahri",
  },
  {
    value: "Qo‘qon shahri",
    label: "Qo‘qon shahri",
  },
  {
    value: "Qo‘shtepa tumani",
    label: "Qo‘shtepa tumani",
  },
  {
    value: "Marg‘ilon shahri",
    label: "Marg‘ilon shahri",
  },
  {
    value: "Oltiariq tumani",
    label: "Oltiariq tumani",
  },
  {
    value: "Rishton tumani",
    label: "Rishton tumani",
  },
  {
    value: "So‘x tumani",
    label: "So‘x tumani",
  },
  {
    value: "Toshloq tumani",
    label: "Toshloq tumani",
  },
  {
    value: "Uchko‘prik tumani",
    label: "Uchko‘prik tumani",
  },
  {
    value: "O‘zbekiston tumani",
    label: "O‘zbekiston tumani",
  },
  {
    value: "Farg‘ona shahri",
    label: "Farg‘ona shahri",
  },
  {
    value: "Farg‘ona tumani",
    label: "Farg‘ona tumani",
  },
  {
    value: "Furqat tumani",
    label: "Furqat tumani",
  },
];

const jins = [
  { value: "MALE", label: "Erkak" },
  { value: "FEMALE", label: "Ayol" },
];
const country = [
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
  },
  {
    value: "Tajikistan",
    label: "Tajikistan",
  },
  {
    value: "Turkmanistan",
    label: "Turkmanistan",
  },
  {
    value: "Afganistan",
    label: "Afganistan",
  },
];
const daraja = [
  {
    value: "O'rta",
    label: "O'rta",
  },
  {
    value: "O'rta maxsus",
    label: "O'rta maxsus",
  },
  {
    value: "Oliy",
    label: "Oliy",
  },
];
const viloyat = [
  {
    value: "Toshkent shahri",
    label: "Toshkent shahri",
  },
  {
    value: "Toshkent viloyati",
    label: "Toshkent viloyati",
  },
  {
    value: "Qashqadaryo viloyati",
    label: "Qashqadaryo viloyati",
  },
  {
    value: "Qoraqalpog‘iston Respublikasi",
    label: "Qoraqalpog‘iston Respublikasi",
  },

  {
    value: "Samarqand viloyati",
    label: "Samarqand viloyati",
  },
  {
    value: "Surxandaryo viloyati",
    label: "Surxandaryo viloyati",
  },
  {
    value: "Navoiy viloyati",
    label: "Navoiy viloyati",
  },
  {
    value: "Jizzax viloyati",
    label: "Jizzax viloyati",
  },
  {
    value: "Buxoro viloyati",
    label: "Buxoro viloyati",
  },
  {
    value: "Farg'ona viloyati",
    label: "Farg'ona viloyati",
  },
  {
    value: "Namangan viloyati",
    label: "Namangan viloyati",
  },
  {
    value: "Xorazm viloyati",
    label: "Xorazm viloyati",
  },
  {
    value: "Sirdaryo viloyati",
    label: "Sirdaryo viloyati",
  },
  {
    value: "Andijon viloyati",
    label: "Andijon viloyati",
  },
];
const middle = [
  {
    value: "Maktab",
    label: "Maktab",
  },
];
const middle_degree = [
  {
    value: "Akademik litsey",
    label: "Akademik litsey",
  },
  {
    value: "Kasb-hunar kolleji",
    label: "Kasb-hunar kolleji",
  },
];
const prime = [
  {
    value: "Oliy ta'lim muassasasi",
    label: "Oliy ta'lim muassasasi",
  },
];
const millat = [
  {
    value: "Arman",
    label: "Arman",
  },
  {
    value: "O'zbek",
    label: "O'zbek",
  },
  {
    value: "Qoraqalpoq",
    label: "Qoraqalpoq",
  },
  {
    value: "Tojik",
    label: "Tojik",
  },
  {
    value: "Qirg'iz",
    label: "Qirg'iz",
  },
  {
    value: "Tatar",
    label: "Tatar",
  },
  {
    value: "Qozoq",
    label: "Qozoq",
  },
  {
    value: "Pushtun",
    label: "Pushtun",
  },
  {
    value: "Turkman",
    label: "Turkman",
  },
];
const typeOfEducation = [
  {
    value: "BAKALAVR_KUNDUZGI",
    label: "Bakalavr (Kunduzgi)",
  },
  {
    value: "SIRTQI",
    label: "Bakalavr (Sirtqi)",
  },
  {
    value: "MAGISTR",
    label: "Magistratura",
  },
];
export {
  typeOfEducation,
  country,
  daraja,
  viloyat,
  middle,
  middle_degree,
  prime,
  jins,
  Qaraqalpaq,
  Andijon,
  Xorazm,
  Namangan,
  Qashqadaryo,
  Surxandaryo,
  Sirdaryo,
  TashkentCity,
  TashkentRegion,
  Navoiy,
  Jizzax,
  Buxoro,
  Fargona,
  Samarqand,
  millat,
};
