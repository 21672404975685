import React, { useContext } from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";

// icons
import FirstPageIcon from "@mui/icons-material/FirstPage.js";
import Footer from "../../components/Footer";

//navigation
import { useNavigate } from "react-router";

//context
import DataContext from "../../context/dataContext";

//style
import "./bachelor.css";

//images
import Maxsus from "../../assets/MAXSUS.png";
import baka from "../../assets/06.png";
import BakalavrImage from "../../assets/BAKALAVR.png";
import { useTranslation } from "react-i18next";
import Lang from "../../components/Lang";

const OptionBachelor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changeBachelor, changeBachelorKunduzgi, changeSirqiBakalavr } =
    useContext(DataContext);
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          my: 2,
          mx: 3,
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Tooltip title={t("exit")}>
            <IconButton sx={{ mr: 1 }} onClick={() => navigate(-1)}>
              <FirstPageIcon sx={{ fontSize: "20px", color: "#234c77" }} />
            </IconButton>
          </Tooltip>
          <Typography variant="h5" gutterBottom sx={{ m: 0 }}>
            {t("bachelor")}
          </Typography>
        </Box>
        <Lang condition="black" />
      </Stack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 4,
          minHeight: "60vh",
          mb: 2,
        }}
      >
        <Box className="each-type-box" onClick={changeBachelor}>
          <Box className="child-box">
            <Typography className="font-box">
              {t("specialEducation")}
            </Typography>
            <img src={Maxsus} alt="logo" width={40} height={40} />
          </Box>
          <Typography>({t("lowerBachelor")})</Typography>
        </Box>

        <Box className="each-type-box" onClick={changeSirqiBakalavr}>
          <Box className="child-box">
            <Typography className="font-box">
              {t("externalEducation")}
            </Typography>
            <img src={BakalavrImage} alt="logo" width={40} height={40} />
          </Box>
          <Typography>({t("lowerBachelor")})</Typography>
        </Box>

        <Box className="each-type-box" onClick={changeBachelorKunduzgi}>
          <Box className="child-box">
            <Typography className="font-box">
              {t("internalEducation")}
            </Typography>
            <img src={baka} alt="logo" width={40} height={40} />
          </Box>
          <Typography>({t("lowerBachelor")})</Typography>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default OptionBachelor;
